import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";

import { IDomain } from "api/types/_domain";
import { INotificationLog } from "api/types/_notification";
import DatePickerCustom from "components/Common/DatePickerCustom";
import InputsRange from "components/Common/InputsRange";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import { formatNumberWithCommas } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import CountUp from "react-countup";
import {
  getMediaStatistics
} from "store/notification/thunk";
import CopyWrapper from "components/Common/CopyWrapper";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "sent";

const NotificationLogList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const { setDurationResponses } = useDurationResponses();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    start_sent: withDefault(StringParam, ""),
    end_sent: withDefault(StringParam, ""),
    start_click: withDefault(StringParam, ""),
    end_click: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ""),
    end_date: withDefault(StringParam, ""),
    order_by: withDefault(StringParam, `DESC`),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;

  const [listDomain, setListDomain] = useState<IDomain[]>([]);

  // Inside your component

  const [notificationLogs, setNotificationLogs] = useState<{
    list: INotificationLog[];
    total: number;
  }>({ list: [], total: 0 });

  const [isDailyStatisticsLoading, setIsDailyStatisticsLoading] = useState<boolean>(false);
  //eslint-disable-next-line

  // Total Send to range
  const [startTotalSend, setTotalNotificationFromRangeSearch] = useState<string>(query?.start_sent || "");
  const [endTotalSend, setTotalNotificationToRangeSearch] = useState<string>(query?.end_sent || "");

  // Total Clicked to range
  const [totalClickedFromRangeSearch, setTotalClickedFromRangeSearch] = useState<string>(query?.start_click || "");
  const [totalClickedToRangeSearch, setTotalClickedToRangeSearch] = useState<string>(query?.end_click || "");

  //eslint-disable-next-line
  const [subscriber, setSubscriber] = useState<any[]>([]);

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1,
    });
  };

  useEffect(() => {
    const handleQueryData = async () => {
      setIsDailyStatisticsLoading((prev) => true);
      const res: any = await getMediaStatistics(query);
      if (res?.code === 200) {
        setNotificationLogs((prev: any) => ({
          list: (prev?.list || []).concat(res?.data?.list || []),
          total: res?.data?.pagination?.total || 0,
        }));

        const listChart = res?.data?.list?.map((item: any) => ({
          date: item.date,
          new_subscriber: item.new_subscriber,
          accumulate_subscriber: item.accumulate_subscriber,
        }));
        setSubscriber(listChart);
      }
      setIsDailyStatisticsLoading((prev) => false);
      setDurationResponses([{
        name: 'Media Statistics',
        time: res?.data?.pagination?.duration || 0
      }]);
    };

    handleQueryData();
    //eslint-disable-next-line
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
      start_sent: startTotalSend || "",
      end_sent: endTotalSend || "",
      start_click: totalClickedFromRangeSearch || "",
      end_click: totalClickedToRangeSearch || "",
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNotificationLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      sort_by: TYPE_SELECT_DEFAULT,
      start_date: "",
      end_date: "",
      page: 1,
      time_request: +new Date(),
      order_by: "DESC",
      start_sent: undefined,
      end_sent: undefined,
      start_click: undefined,
      end_click: undefined,
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNotificationLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew, "push");
    setDateSearch(["", ""]);
    setTotalNotificationFromRangeSearch("");
    setTotalNotificationToRangeSearch("");
    setTotalClickedFromRangeSearch("");
    setTotalClickedToRangeSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Media"),
        accessor: "media",
        filterable: true,
        sortable: false,
        thWidth: 180,
        minWidth: 180,
        Cell: (cell: any) => {
          return (
            <>
              <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
            </>
          );
        },
      },
      {
        Header: t("Campaigns"),
        accessor: "campaigns",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("Sent"),
        accessor: "sent",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-end">
                {formatNumberWithCommas(cell?.value)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Click"),
        accessor: "click",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("CTR"),
        accessor: "ctr",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        Cell: (cell: any) => (
          <>
            <div className="text-end">
              {formatNumberWithCommas(cell?.value || 0)}%
            </div>
          </>
        ),
      },
    ],
    //eslint-disable-next-line
    [i18n?.language, userPermissions]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = (sortBy: any) => {
    if (isDailyStatisticsLoading) {
      return;
    } else {
      setNotificationLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
      setQuery((_prev: any) => {
        return { ..._prev, ...sortBy };
      });
    }
  };

  useEffect(() => {
    document.title = `${t("Daily Statistics")} - ${t(
      "Statistics"
    )} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Media Statistics")}
            pageTitle={t("Statistics")}
          />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.NEWS_LOG_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList" className="ribbon-box right">
                    <div
                      className="ribbon round-shape"
                      style={{
                        background: "#1548a0",
                        fontWeight: 300,
                        fontSize: "0.8rem",
                      }}
                    >
                      {t("This data is analyzed per 10 mins")}
                    </div>
                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                        <Card
                          className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
                          style={{ width: "200px" }}
                        >
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                                  {t("Total")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isDailyStatisticsLoading ? (
                                      <Spinner size="sm"></Spinner>
                                    ) : (
                                      <CountUp
                                        start={0}
                                        end={notificationLogs?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <Row className="g-4 align-items-center mt-0">
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="date-picker-wrapper-custom mt-3"
                            >
                              <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                <DatePickerCustom
                                  placeholder={`${t("Start Date")} - ${t(
                                    "End Date"
                                  )}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  onChangePicker={handleChangePicker}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={startTotalSend}
                                valueTo={endTotalSend}
                                placeholderFrom={`${t("From Range Total Send")}...`}
                                placeholderTo={`${t("To Range Total Send")}...`}
                                labelFrom={`${t('From Range Total Send')}`}
                                labelTo={`${t('To Range Total Send')}`}
                                onChangeFrom={(val) =>
                                  setTotalNotificationFromRangeSearch(val)
                                }
                                onChangeTo={(val) =>
                                  setTotalNotificationToRangeSearch(val)
                                }
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={totalClickedFromRangeSearch}
                                valueTo={totalClickedToRangeSearch}
                                placeholderFrom={`${t("From Range Total Clicked")}...`}
                                placeholderTo={`${t("To Range Total End")}...`}
                                labelFrom={`${t('From Range Total Clicked')}`}
                                labelTo={`${t('To Range Total End')}`}
                                onChangeFrom={(val) =>
                                  setTotalClickedFromRangeSearch(val)
                                }
                                onChangeTo={(val) =>
                                  setTotalClickedToRangeSearch(val)
                                }
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>

                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="hstack gap-1 justify-content-sm-start justify-content-md-start mt-3"
                            >
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary me-1"
                                  onClick={searchData}
                                  disabled={isDailyStatisticsLoading}
                                >
                                  <i className="ri-search-line align-bottom me-1"></i>{" "}
                                  {t("Button Search")}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary fs-14"
                                  onClick={resetData}
                                >
                                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                  {t("Button Reset")}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardHeader>

                    <CardBody className="pt-0 px-0">
                      <div>
                        <InfiniteScroll
                          dataLength={notificationLogs?.list?.length || 0}
                          next={handleLoadMore}
                          scrollableTarget="scrollableDiv"
                          hasMore={
                            notificationLogs &&
                              notificationLogs?.list?.length <
                              notificationLogs?.total
                              ? true
                              : false
                          }
                          loader={""} // loader={<LoadingListNotify />}
                          scrollThreshold={"50%"}
                        >
                          <div className="mx-3 my-4">
                            <TableContainer
                              className="custom-header-css"
                              divClass="table-card"
                              tableClass="align-middle"
                              theadClass="table-light"
                              columns={columns}
                              data={notificationLogs?.list?.length ? notificationLogs?.list : []}
                              customPageSize={query.limit}
                              customPageIndex={query.page - 1}
                              totalRecords={notificationLogs?.total}
                              customPageCount={1}
                              handleChangePage={handleChangePage}
                              manualSorting={true}
                              sorting={{
                                sort_by: query.sort_by,
                                order_by: query.order_by,
                              }}
                              handleChangeSorting={handleChangeSorting}
                              isLoading={isDailyStatisticsLoading}
                              isShowPagination={false}
                              isShowLoadingBottom={query.page > 1}
                            />
                          </div>
                        </InfiniteScroll>
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotificationLogList;
