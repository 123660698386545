import { ICampaign } from "api/types/_campaign";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import InputsRange from "components/Common/InputsRange";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import SearchFilterDomain from "components/Common/SearchFilterDomain";
import SearchFilterTag from "components/Common/SearchFilterTag";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import {
  CAMPAIGN_TYPE_OPTIONS,
  OS_OPTIONS,
  SCHEDULE_MONTHLY_OPTIONS,
  SCHEDULE_OPTIONS,
  SCHEDULE_WEEKLY_OPTIONS,
  STATUS_OPTIONS_CAMPAIGN,
} from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import { getAllDomains } from "store/domain/thunk";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import {
  deleteCampaign,
  getHashTags,
  getCampaigns as onGetCampaigns,
} from "../../../store/thunks";
import CampaignForm from "../CampaignForm/CampaignForm";
import MultiTableWinner from "../Components/MultiTableWinner";

const typeQuery = {
  start_total_send: StringParam,
  end_total_send: StringParam,
  start_total_clicked: StringParam,
  end_total_clicked: StringParam,
};

const TYPE_SELECT_DEFAULT: string = "created_at";

const COLORS_STATUS_CAMPAIGN: any = {
  pending: "primary",
  running: "success",
  finished: "danger",
  waiting: "primary",
  error: "warning",
  processing: "secondary",
};

const DATA_CAMPAIGN_DEFAULT = {
  campaignName: "",
};

registerLocale("en", en);
registerLocale("ko", ko);

const CampaignList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    media_id: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ""),
    end_date: withDefault(StringParam, ""),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    id: withDefault(StringParam, ""),
    name: withDefault(StringParam, ""),
    os: withDefault(StringParam, ""),
    start_ctr: withDefault(StringParam, ""),
    end_ctr: withDefault(StringParam, ""),
    status: withDefault(StringParam, ""),
    hashtags: withDefault(StringParam, ""),
    targettags: withDefault(StringParam, ""),
    schedule: withDefault(StringParam, ""),
    date: withDefault(StringParam, ""),
    time: withDefault(StringParam, ""),
    type: withDefault(StringParam, ""),
    ...typeQuery,
  });

  const OPTIONS_MUST_HAVE_DATE = ["weekly", "monthly"];

  const MONTHLY_OPTIONS =
    SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({
      ...item,
      type: "monthly",
    })) || [];

  const WEEKLY_OPTIONS =
    SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({
      ...item,
      type: "weekly",
    })) || [];

  const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map(
    (item: any) => ({ ...item, label: t(item?.label) })
  );

  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const SCHEDULE_WEEKLY_OPTIONS_LANG = SCHEDULE_WEEKLY_OPTIONS?.map(
    (item: any) => ({ value: item?.value, label: t(item?.label) })
  );

  const OS_OPTIONS_LANG =
    OS_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const STATUS_OPTIONS_CAMPAIGN_LANG =
    STATUS_OPTIONS_CAMPAIGN?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const CAMPAIGN_TYPE_OPTIONS_LANG = CAMPAIGN_TYPE_OPTIONS?.map(
    (item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })
  );

  const rangeValue = useRef<any>();
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] =
    useState<ICampaign | null>(null);

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);

  const [startDate, endDate] = dateSearch;
  const [previewDetail, setPreviewDetail] = useState<any | null>(null);

  const [dataCampaign, setDataCampaign] = useState<ICampaign | any>(
    COLORS_STATUS_CAMPAIGN
  );
  // Begin::List Option Edit Campaign

  const [listDomain, setListDomain] = useState<IMedia[]>([]);

  const [domainSearch, setDomainSearch] = useState<Option | null>({
    label:
      listDomain?.filter(
        (item) => String(item?.website || "") === String(query?.domain || "")
      )[0]?.name || t("All Media"),
    value: `${listDomain?.filter(
      (item) => String(item?.website || "") === String(query?.domain || "")
    )[0]?.id || ""
      }`,
  });

  // Total Notification to range
  const [startTotalSend, setTotalNotificationFromRangeSearch] =
    useState<string>(query?.start_total_send || "");
  const [endTotalSend, setTotalNotificationToRangeSearch] = useState<string>(
    query?.end_total_send || ""
  );

  // Total Clicked to range
  const [totalClickedFromRangeSearch, setTotalClickedFromRangeSearch] =
    useState<string>(query?.start_total_clicked || "");
  const [totalClickedToRangeSearch, setTotalClickedToRangeSearch] =
    useState<string>(query?.end_total_clicked || "");

  const [isCopy, setIsCopy] = useState<boolean>(false);

  const [campaignNoSearch, setCampaignNoSearch] = useState<string>(
    query?.id || ""
  );
  const [campaignNameSearch, setCampaignNameSearch] = useState<string>(
    query?.name || ""
  );

  const [osSearch, setOsSearch] = useState<Option | null>(
    OS_OPTIONS_LANG?.filter(
      (item: any) => String(item?.value) === String(query?.os)
    )[0]
  );

  const [listStatus, setListStatus] = useState<any>(
    STATUS_OPTIONS_CAMPAIGN_LANG
  );
  const [statusSearch, setStatusSearch] = useState<Option | null>(
    STATUS_OPTIONS_CAMPAIGN_LANG?.filter(
      (item: any) => String(item?.value) === String(query?.status)
    )[0] || { label: t("All Status"), value: "" }
  );

  const [campaignTypeSearch, setCampaignTypeSearch] = useState<Option | null>(
    CAMPAIGN_TYPE_OPTIONS_LANG?.filter(
      (item: any) => String(item?.value) === String(query?.type)
    )[0] || { label: t("All Campaign Type"), value: "" }
  );

  const [listHashtag, setListHashtag] = useState<any>([]);
  const [hashtagSearch, setHashtagSearch] = useState<any | null>(
    query?.hashtags
      ? (query?.hashtags || "")
        .split(",")
        ?.map((item: any) => ({ label: item, value: item }))
      : []
  );

  const [targetTagSearch, setTargetTagSearch] = useState<any | null>(
    query?.targettags
      ? (query?.targettags || "")
        .split(",")
        ?.map((item: any) => ({ label: item, value: item }))
      : []
  );

  const [scheduleSearch, setScheduleSearch] = useState<any>(
    SCHEDULE_OPTIONS_LANG?.filter(
      (e: any) => e.value === query?.schedule
    )[0] || { label: t("All Schedule"), value: "" }
  );
  const [dateNormalSearch, setDateNormalSearch] = useState<any>(
    (DATE_OPTIONS_LANG?.filter(
      (op) => op?.type === query?.schedule && op?.value === query?.date
    ) || [])[0] || { label: t("All Date"), value: "" }
  );
  const [dateCustomSearch, setDateCustomSearch] = useState<any>(
    query?.schedule === "custom" ? query?.date : ""
  );
  const [timeSearch, setTimeSearch] = useState<any>(query?.time || "");

  // ctr to range
  const [startCTR, setStartCTRFromRangeSearch] = useState<string>(
    query?.start_ctr || ""
  );
  const [endCTR, setEndCTRToRangeSearch] = useState<string>(
    query?.end_ctr || ""
  );

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Campaign;

  function onClosePreviewDetailClick() {
    setPreviewDetail((_prev: any) => null);
  }

  const handlePreviewDetail = async (item: any | null = null) => {
    try {
      setPreviewDetail((_prev: any) => item);
    } catch (error: any) {
      return error;
    }
  };

  const CampaignProperties = createSelector(selectLayoutState, (state) => ({
    campaigns: state.campaigns,
    isCampaignSuccess: state.isCampaignSuccess,
    isCampaignLoading: state.isCampaignLoading,
    error: state.error,
  }));

  // Inside your component
  const { campaigns, isCampaignLoading } = useSelector(CampaignProperties);

  useEffect(() => {
    dispatch(
      onGetCampaigns(
        query.sort_by && rangeValue.current
          ? { ...query, [query.sort_by]: rangeValue.current?.value }
          : query
      )
    );
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      page: 1,
      media_id: domainSearch?.value
        ? listDomain?.filter(
          (item: any) => Number(item?.id) === Number(domainSearch?.value)
        )[0]?.website || domainSearch?.value
        : "",
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      time_request: +new Date(),
      sort_by: TYPE_SELECT_DEFAULT,
      start_total_send: startTotalSend || "",
      end_total_send: endTotalSend || "",
      start_total_clicked: totalClickedFromRangeSearch || "",
      end_total_clicked: totalClickedToRangeSearch || "",
      id: campaignNoSearch || "",
      name: campaignNameSearch || "",
      os: osSearch?.value ?? "",
      start_ctr: startCTR || "",
      end_ctr: endCTR || "",
      status: statusSearch?.value ?? "",
      hashtags: hashtagSearch?.map((item: any) => item?.label)?.join(",") || "",
      targettags: targetTagSearch?.map((item: any) => item.label).join(","),
      schedule: scheduleSearch?.value || "",
      date: ["weekly", "monthly"]?.includes(scheduleSearch?.value)
        ? dateNormalSearch?.value
        : scheduleSearch?.value === "custom"
          ? dateCustomSearch
          : "",
      time: scheduleSearch?.value === "now" ? "" : timeSearch || "",
      type: campaignTypeSearch?.value || "",
    });
  };

  const resetData = () => {
    setQuery(
      {
        media_id: undefined,
        start_date: undefined,
        end_date: undefined,
        time_request: +new Date(),
        start_total_send: undefined,
        end_total_send: undefined,
        start_total_clicked: undefined,
        end_total_clicked: undefined,
        id: undefined,
        name: undefined,
        os: undefined,
        start_ctr: undefined,
        end_ctr: undefined,
        status: "",
        hashtags: "",
        targettags: "",
        schedule: "",
        date: "",
        time: "",
        type: "",
      },
      "push"
    );
    setDateSearch(["", ""]);
    setDomainSearch({ label: t("All Media"), value: "" });
    setTotalNotificationFromRangeSearch("");
    setTotalNotificationToRangeSearch("");
    setTotalClickedFromRangeSearch("");
    setTotalClickedToRangeSearch("");
    setCampaignNoSearch("");
    setCampaignNameSearch("");
    setOsSearch(null);
    setStartCTRFromRangeSearch("");
    setEndCTRToRangeSearch("");
    setStatusSearch({ label: t("All Status"), value: "" });
    setHashtagSearch([]);
    setTargetTagSearch([]);
    setScheduleSearch({ label: t("All Schedule"), value: "" });
    setDateNormalSearch(null);
    setDateCustomSearch("");
    setTimeSearch("");
    setCampaignTypeSearch({ label: t("All Campaign Type"), value: "" });
  };

  const formatDate = (strDate: string = "") => {
    if (!strDate) {
      return "";
    }
    return `${String(strDate).split("T")[0]} ${String(String(strDate).split("T")[1]).split(".")[0]
      }`;
  };

  // Begin::Delete
  const handleConfirmDelete = (item: ICampaign) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteCampaign(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetCampaigns(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  };
  // End::Delete

  const getTextSchedule = (key: string = "", item: any = {}) => {
    if (key === "scheduleValue") {
      let day = item?.scheduleValue || "";
      if (item?.scheduleType === "weekly") {
        day =
          SCHEDULE_WEEKLY_OPTIONS?.filter(
            (it: any) => String(it?.value) === String(day)
          )[0]?.label || "";
      } else if (item?.scheduleType === "monthly") {
        day =
          SCHEDULE_MONTHLY_OPTIONS?.filter(
            (it: any) => String(it?.value) === String(day)
          )[0]?.label || "";
      }
      return day ? t(`${day}`) : day;
    }
    if (key === "scheduleType") {
      let scheduleType: any =
        SCHEDULE_OPTIONS_LANG?.filter(
          (it: any) => String(it?.value) === String(item?.scheduleType)
        )[0]?.label || "";
      if (item?.schedule === "immediate") {
        scheduleType = t("Now");
      }
      if (item?.schedule === "reservation") {
        scheduleType = t("Custom");
      }
      return scheduleType;
    }
    return item?.[key] || "";
  };

  const handleOnEditCampaign = (item: ICampaign, isCopy = false) => {
    setDataCampaign({ ...item });
    setIsCopy(isCopy);
  };

  const showListDomain = (domain: any) => {
    if (domain) {
      const valueConvertArr = domain.split(",");
      const show: any[] = [];
      // eslint-disable-next-line
      valueConvertArr?.map((value: any) => {
        show.push(
          <div>
            <Link
              className="text-normal"
              to={
                String(value).includes("https://") ? value : `https://${value}`
              }
              target="_blank"
            >
              {value}
            </Link>
          </div>
        );
      });

      return (
        <div className="d-flex" style={{ flexDirection: "column", gap: "2px" }}>
          {show}
        </div>
      );
    } else {
      return null;
    }
  };

  const showListMedia = (media: any) => {
    if (media) {
      const show: any[] = [];
      // eslint-disable-next-line
      media?.map((value: any, key: number) => {
        show.push(
          <div
            className={`d-flex flex-column ${key >= 1 && "mt-2"}`}
            style={{ minWidth: "max-content" }}
          >
            <div>{value?.name}</div>
            <div className="cursor-pointer">
              {showListDomain(value?.domain)}
            </div>
          </div>
        );
      });

      return show;
    } else {
      return null;
    }
  };

  // Begin::Winner
  const [campaignSelectViewWinner, setCampaignSelectViewWinner] =
    useState<any>(null);

  // End::Winner

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Campaign Name"),
        accessor: "name",
        filterable: true,
        sortable: true,
        thWidth: 170,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div>
                <CopyWrapper contentCopy={cell?.value}>
                  {cell?.value}
                </CopyWrapper>
                <div className="text-muted">ID: {item?.id_campaign || ""}</div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Notification"),
        accessor: "title",
        filterable: true,
        sortable: false,
        thWidth: 250,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const {
            push_title: title,
            image_url: imageURL,
            push_content: content,
            push_url: url,
          } = item;
          return (
            <Link
              className="text-normal text-center"
              to={
                String(url || "").includes("https://") ? url : `https://${url}`
              }
              target="_blank"
            >
              <div className="d-flex div-warper-notification">
                <div
                  className="title-warper-notification text-start"
                  style={{ width: "100%" }}
                >
                  {title}
                </div>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="content-warper-notification text-muted text-start"
                    style={{
                      width: `calc(100% - ${!!imageURL ? "40px" : "0px"})`,
                    }}
                  >
                    {content}
                  </div>
                  {!!imageURL && (
                    <div>
                      <img
                        src={
                          String(imageURL || "").includes("https://")
                            ? imageURL
                            : `https://${imageURL}`
                        }
                        className="flex-shrink-0 rounded"
                        style={{
                          width: "36px",
                          height: "auto",
                          maxHeight: "36px",
                          objectFit: "cover",
                          backgroundColor: "#f8f8f8",
                        }}
                        alt="notification"
                      />
                    </div>
                  )}
                </div>
                <div className="text-start">{url}</div>
              </div>
            </Link>
          );
        },
      },
      {
        Header: t("Media"),
        accessor: "media",
        filterable: true,
        sortable: false,
        thWidth: 230,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const { media } = item;
          return <>{showListMedia(media)}</>;
        },
      },
      {
        Header: t("Click Tag"),
        accessor: "hashtags",
        filterable: true,
        sortable: false,
        thWidth: 150,
        cellClass: "mb-auto",
        description: t(
          "This tag will be stored when click push notification message"
        ),
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const hashtags = value?.hashtags || [];
          return (
            <div style={{ minWidth: "100px" }}>
              {hashtags?.map((k: any, index: number) => (
                <span className="tag-style" key={index}>
                  {k}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: t("Target Tag"),
        accessor: "targettags",
        filterable: true,
        sortable: false,
        thWidth: 150,
        cellClass: "mb-auto",
        description: t(
          "The message will be sent to users who clicked message with this tag"
        ),
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const targettags = value?.targettags || [];
          return (
            <div style={{ minWidth: "100px" }}>
              {targettags?.map((k: any, index: number) => (
                <span className="tag-style" key={index}>
                  {k}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: t("Schedule"),
        accessor: "schedule",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          let schedule = getTextSchedule("schedule", item);
          return (
            <>
              <div className="text-center" style={{ minWidth: "60px" }}>
                {t(schedule)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Date"),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          let day = getTextSchedule("date", item);
          return (
            <>
              <div className="text-center" style={{ minWidth: "80px" }}>
                {day || ""}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Time"),
        accessor: "time",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "justify-content-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: "60px" }}>
              {cell?.value}
            </div>
          </>
        ),
      },
      {
        Header: t("Status"),
        accessor: "status",
        filterable: true,
        sortable: false,
        thWidth: 90,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = value?.status;
          const color = COLORS_STATUS_CAMPAIGN?.[`${status}`];
          return (
            <div style={{ minWidth: "80px" }} className="text-center">
              <span className={`badge bg-${color}`}>{`${t(
                `Status_Campaign_${status}`
              )}`}</span>
            </div>
          );
        },
      },
      {
        Header: t("Sent"),
        accessor: "total_send",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end",
        thWidth: 140,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const { total_to_send } = value;
          return (
            <>
              <div className="text-end" style={{ minWidth: "110px" }}>
                {formatNumberWithCommas(cell?.value)} /{" "}
                {formatNumberWithCommas(total_to_send)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Click"),
        accessor: "total_clicked",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: "justify-content-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center">
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("CTR"),
        accessor: "ctr",
        filterable: true,
        sortable: true,
        thClass: "justify-content-center",
        thWidth: 120,
        Cell: (cell: any) => (
          <>
            <div className="text-center">
              {formatNumberWithCommas(cell?.value || 0)}%
            </div>
          </>
        ),
      },
      {
        Header: t("Expiry period of event"),
        accessor: "event_expiration_date",
        filterable: true,
        sortable: false,
        thClass: "justify-content-end text-end",
        thWidth: 200,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          return (
            <>
              <div className="text-end" style={{ minWidth: "170px" }}>
                {cell?.value}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Total winner"),
        accessor: "winner_total",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center text-center",
        thWidth: 180,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div
                className="text-center cursor-pointer text-primary"
                style={{ minWidth: "140px" }}
                onClick={() =>
                  setCampaignSelectViewWinner({
                    id: item?.id,
                    name: item?.name,
                    first_come_first_served: item?.first_come_first_served,
                    first_come_first_served_total:
                      item?.first_come_first_served_total,
                    winners_selected_through_draw:
                      item?.winners_selected_through_draw,
                    winners_selected_through_draw_total:
                      item?.winners_selected_through_draw_total,
                    exclude_existing_winners: item?.exclude_existing_winners,
                    event_expiration_date: item?.event_expiration_date,
                    totalWinners:
                      item?.first_come_first_served +
                      item?.first_come_first_served_total,
                  })
                }
              >
                <div
                  className={`badge ${cell?.value > 0 ? "bg-primary" : "bg-danger"
                    }  ms-2`}
                >
                  <strong>
                    {formatNumberWithCommas(item?.first_come_first_served_total +
                      item?.winners_selected_through_draw_total)}
                    {" / "}
                    {formatNumberWithCommas(
                      item?.first_come_first_served +
                      item?.first_come_first_served_total
                    )}
                  </strong>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 130,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          const arrDate = formatDate(cell?.value).split(" ");
          return (
            <>
              <div className="text-end">
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "justify-content-center",
        thWidth: 120,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              <TooltipCustom title={t("Detail")} id={`detail-log-${item?.id}`}>
                <li
                  className=" list-inline-item me-0"
                  id={`detail-log-${item?.id}`}
                >
                  <Link
                    className="btn btn-sm btn-soft-secondary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePreviewDetail(item);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
              {isHavePermissionRole(
                ROLES_FOR_APP.CAMPAIGN_UPDATE,
                userPermissions
              ) &&
                (item?.schedule !== "now" &&
                  !["running", "finished"].includes(item?.status) ? (
                  <TooltipCustom
                    title={t("Button Update Campaign")}
                    id={`update-cp-${item?.id}`}
                  >
                    <li
                      className="list-inline-item"
                      id={`update-cp-${item?.id}`}
                    >
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOnEditCampaign(item);
                        }}
                      >
                        <i className="ri-pencil-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                ) : (
                  <TooltipCustom
                    title={t("You cannot edit this campaign")}
                    id={`update-cp-${item?.id}`}
                  >
                    <li
                      className="list-inline-item"
                      id={`update-cp-${item?.id}`}
                    >
                      <div
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        style={{ opacity: 0.4, cursor: "not-allowed" }}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <i className="ri-pencil-fill align-bottom"></i>
                      </div>
                    </li>
                  </TooltipCustom>
                ))}
              {isHavePermissionRole(
                ROLES_FOR_APP.CAMPAIGN_CREATE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Copy Campaign")}
                    id={`copy-cp-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`copy-cp-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOnEditCampaign(item, true);
                        }}
                      >
                        <i className="ri-file-copy-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
              {isHavePermissionRole(
                ROLES_FOR_APP.CAMPAIGN_DELETE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Delete Campaign")}
                    id={`detail-cp-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleConfirmDelete(item);
                        }}
                      >
                        <i className="ri-delete-bin-3-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
            </ul>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [
      i18n?.language,
      userPermissions,
      SCHEDULE_OPTIONS_LANG,
      SCHEDULE_WEEKLY_OPTIONS_LANG,
    ]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (domainSearch) {
      setListDomain((_prev) =>
        (_prev || [])?.map((item) =>
          item?.id ? item : { ...item, name: t("All Media") }
        )
      );
      !domainSearch?.value &&
        setDomainSearch(() => ({ label: t("All Media"), value: "" }));
    }

    if (osSearch) {
      setOsSearch(
        (_prev: any) =>
          OS_OPTIONS_LANG?.filter((e: any) => e.value === osSearch?.value)[0]
      );
    }

    if (statusSearch) {
      setStatusSearch(
        (_prev: any) =>
          STATUS_OPTIONS_CAMPAIGN_LANG?.filter(
            (e: any) => e.value === statusSearch?.value
          )[0] || { label: t("All Status"), value: "" }
      );
    }

    if (campaignTypeSearch) {
      setCampaignTypeSearch(
        (_prev: any) =>
          CAMPAIGN_TYPE_OPTIONS_LANG?.filter(
            (e: any) => e.value === campaignTypeSearch?.value
          )[0] || { label: t("All Campaign Type"), value: "" }
      );
    }

    setScheduleSearch(
      (_prev: any) =>
        SCHEDULE_OPTIONS_LANG?.filter(
          (e: any) => e.value === scheduleSearch?.value
        )[0] || { label: t("All Schedule"), value: "" }
    );

    if (scheduleSearch?.value) {
      setDateNormalSearch(
        (_prev: any) =>
          (DATE_OPTIONS_LANG?.filter(
            (op) =>
              op?.type === scheduleSearch?.value &&
              op?.value === dateNormalSearch?.value
          ) || [])[0] || { label: t("All Date"), value: "" }
      );
      setDateCustomSearch(dateCustomSearch || "");
      setTimeSearch(timeSearch || "");
    }

    if (listStatus) {
      setListStatus(STATUS_OPTIONS_CAMPAIGN_LANG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Campaign")} - ${t("Campaign")} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [resDomain, resTag]: any = await Promise.all([
          getAllDomains(),
          getHashTags(),
        ]);
        if (resDomain) {
          const res = resDomain?.data?.list?.map(
            (item: any, index: number) => ({
              id: item?.id,
              name: item?.name,
              website: item?.domain,
            })
          );
          const list = [
            { name: t("All Media"), id: "", website: "" } as any,
          ].concat(res || []);
          setListDomain((_prev) => list);
        }
        if (resTag) {
          const newData = resTag?.data?.list?.map((item: any) => ({
            value: item?.name,
            label: item?.name,
          }));
          setListHashtag(newData);
        }
      } catch (error: any) {
        return error;
      }
    };

    handleCallAllOption();
  }, []);

  const decideIsModalEditOpen = () => {
    return !!dataCampaign?.id;
  };

  const handleCloseModalEdit = () => {
    setDataCampaign(DATA_CAMPAIGN_DEFAULT);
    setIsCopy(false);
  };

  const triggerRefresh = () => {
    handleCloseModalEdit();
    dispatch(onGetCampaigns(query));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Campaign")} pageTitle={t("Campaign")} />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.CAMPAIGN_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <Row>
                        <Col
                          sm={12}
                          className="text-end  mb-2 mb-md-0 d-block d-xxl-none"
                        >
                          <div className="d-flex flex g-4 align-items-end align-items-md-end mb-2 mt-2">
                            <Card
                              className="card-animate mb-0 me-0 me-md-4 bg-primary-subtle text-primary border-0"
                              style={{ width: "200px" }}
                            >
                              <CardBody>
                                <div className="d-flex align-items-center ">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-primary text-truncate mb-0 text-start">
                                      {t("Total")}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                      <span className="counter-value text-primary">
                                        <CountUp
                                          start={0}
                                          end={campaigns?.pagination?.total || 0}
                                          duration={1}
                                        />
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="w-100 d-flex flex-column align-items-end">
                              {isHavePermissionRole(
                                ROLES_FOR_APP.CAMPAIGN_CREATE,
                                userPermissions
                              ) && (
                                  <button
                                    type="button"
                                    className="btn btn-success ms-2 mt-2"
                                    onClick={() => navigate("/campaign/create")}
                                    style={{ width: "170px" }}
                                  >
                                    <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                    {t("Button Create Campaign")}
                                  </button>
                                )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-xl-start mb-2 mt-2">
                        <Card
                          className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0 d-none d-xxl-block"
                          style={{ width: "200px" }}
                        >
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                                  {t("Total")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isCampaignLoading ? (
                                      <Spinner size="sm"></Spinner>
                                    ) : (
                                      <CountUp
                                        start={0}
                                        end={campaigns?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <div className="w-100">
                          <Row className="g-4 align-items-center mb-2 mb-md-0 mt-0">
                            <Col sm={12} md={6} xl={4} xxl={3} className="mt-3">
                              <LabelWrapper
                                label={t("Campaign Type")}
                                isShow={!!campaignTypeSearch?.value}
                              >
                                <DropdownOption
                                  name="format"
                                  dataList={CAMPAIGN_TYPE_OPTIONS_LANG || []}
                                  placeholder={`${t("Campaign Type")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={campaignTypeSearch || null}
                                  onChangeSelect={(e: any) => {
                                    setCampaignTypeSearch((prev: any) => e);
                                  }}
                                  isHasOptionAll={true}
                                  optionAll={{
                                    label: t("All Campaign Type"),
                                    value: "",
                                  }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={4} xxl={3} className="mt-3">
                              <LabelWrapper
                                label={t("Media")}
                                isShow={!!domainSearch?.value}
                              >
                                <SearchFilterDomain
                                  name="domains"
                                  isMulti={false}
                                  dataList={listDomain}
                                  initialValue={domainSearch}
                                  onChangeSelect={(event: any) => {
                                    setDomainSearch((_prev) => event);
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={6}
                              xl={4}
                              xxl={3}
                              lg={6}
                              className="mt-3"
                            >
                              <LabelWrapper
                                label={t("Status")}
                                isShow={!!statusSearch?.value}
                              >
                                <DropdownOption
                                  name="format"
                                  dataList={listStatus || []}
                                  placeholder={`${t("Status")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={statusSearch || null}
                                  onChangeSelect={(e: any) => setStatusSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{
                                    label: t("All Status"),
                                    value: "",
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={6}
                              xl={4}
                              xxl={3}
                              className="date-picker-wrapper-custom mt-3"
                            >
                              <LabelWrapper
                                label={t("Date")}
                                isShow={!!startDate || !!endDate}
                              >
                                <DatePickerCustom
                                  placeholder={`${t("Start Date")} - ${t(
                                    "End Date"
                                  )}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  onChangePicker={handleChangePicker}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={startTotalSend}
                                valueTo={endTotalSend}
                                placeholderFrom={`${t(
                                  "From Range Total Send"
                                )}...`}
                                placeholderTo={`${t("To Range Total Send")}...`}
                                labelFrom={`${t("From Range Total Send")}`}
                                labelTo={`${t("To Range Total Send")}`}
                                onChangeFrom={(val) =>
                                  setTotalNotificationFromRangeSearch(val)
                                }
                                onChangeTo={(val) =>
                                  setTotalNotificationToRangeSearch(val)
                                }
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>

                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={totalClickedFromRangeSearch}
                                valueTo={totalClickedToRangeSearch}
                                placeholderFrom={`${t(
                                  "From Range Total Clicked"
                                )}...`}
                                placeholderTo={`${t("To Range Total End")}...`}
                                labelFrom={`${t("From Range Total Clicked")}`}
                                labelTo={`${t("To Range Total End")}`}
                                onChangeFrom={(val) =>
                                  setTotalClickedFromRangeSearch(val)
                                }
                                onChangeTo={(val) =>
                                  setTotalClickedToRangeSearch(val)
                                }
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>
                            <Col
                              sm={12}
                              md={6}
                              lg={6}
                              xl={4}
                              xxl={3}
                              className="mt-3"
                            >
                              <LabelWrapper
                                label={t("Click Tag")}
                                isShow={hashtagSearch?.length > 0}
                              >
                                <DropdownOption
                                  name="format"
                                  dataList={listHashtag || []}
                                  placeholder={`${t("Click Tag")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={hashtagSearch || null}
                                  onChangeSelect={(e: any) => {
                                    setHashtagSearch((prev: any) => e);
                                  }}
                                  isHasOptionAll={false}
                                  isMulti
                                  isClearable={true}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col
                              sm={12}
                              md={6}
                              lg={6}
                              xl={4}
                              xxl={3}
                              className="mt-3"
                            >
                              <LabelWrapper
                                label={t("Target Tag")}
                                isShow={targetTagSearch?.length > 0}
                              >
                                <SearchFilterTag
                                  isMulti={true}
                                  limitShow={10}
                                  initialValue={targetTagSearch}
                                  isClearable={true}
                                  onChangeSelect={(e) => {
                                    setTargetTagSearch((prev: any) => e);
                                  }}
                                  placeholder={`${t("Target Tag")}`}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} md={6} xl={4} xxl={3} className="mt-3">
                              <LabelWrapper
                                label={t("Campaign Name")}
                                isShow={!!campaignNameSearch}
                              >
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("Campaign Name")}...`}
                                  value={campaignNameSearch}
                                  onChange={(e) =>
                                    setCampaignNameSearch(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={6}
                              lg={6}
                              xl={4}
                              xxl={3}
                              className="mt-3"
                            >
                              <LabelWrapper
                                label={t("Schedule")}
                                isShow={!!scheduleSearch?.value}
                              >
                                <DropdownOption
                                  name="schedule"
                                  dataList={SCHEDULE_OPTIONS_LANG || []}
                                  placeholder={`${t("Schedule")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={scheduleSearch || null}
                                  onChangeSelect={(e: any) => {
                                    setScheduleSearch(e);
                                    setDateNormalSearch({
                                      label: t("All Date"),
                                      value: "",
                                    });
                                    setDateCustomSearch("");
                                    setTimeSearch("");
                                  }}
                                  isHasOptionAll={true}
                                  optionAll={{
                                    label: t("All Schedule"),
                                    value: "",
                                  }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col
                              sm={12}
                              md={6}
                              lg={6}
                              xl={4}
                              xxl={3}
                              className="mt-3"
                            >
                              <LabelWrapper
                                label={t("Date")}
                                isShow={!!scheduleSearch?.value}
                              >
                                {scheduleSearch?.value !== "custom" ? (
                                  <DropdownOption
                                    name="date"
                                    dataList={
                                      DATE_OPTIONS_LANG?.filter(
                                        (op) => op?.type === scheduleSearch?.value
                                      ) || []
                                    }
                                    placeholder={`${t("Date")}...`}
                                    className="search-filter-category-type"
                                    classNamePrefix="name-prefix"
                                    initialValue={dateNormalSearch || null}
                                    disabled={
                                      !OPTIONS_MUST_HAVE_DATE?.includes(
                                        scheduleSearch?.value
                                      )
                                    }
                                    onChangeSelect={(e: any) => {
                                      setDateNormalSearch(e);
                                    }}
                                    isHasOptionAll={true}
                                    optionAll={{
                                      label: t("All Date"),
                                      value: "",
                                    }}
                                  />
                                ) : (
                                  <div className="date-picker-wrapper-custom">
                                    <DatePicker
                                      className="form-control search w-100"
                                      placeholderText={`${t("Date")}...`}
                                      isClearable={true}
                                      dateFormat="yyyy-MM-DD"
                                      locale={
                                        i18n?.language === "ko" ? "ko" : "en"
                                      }
                                      value={
                                        scheduleSearch?.value !== "custom"
                                          ? ""
                                          : dateCustomSearch
                                      }
                                      name={`custom_date`}
                                      onChange={(value: Date | null) => {
                                        setDateCustomSearch(
                                          value
                                            ? moment(new Date(value)).format(
                                              "yyyy-MM-DD"
                                            )
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={6}
                              lg={6}
                              xl={4}
                              xxl={3}
                              className="mt-3"
                            >
                              <div className="date-picker-wrapper-custom">
                                <DatePicker
                                  className="form-control search"
                                  placeholderText={`${t("Time")}...`}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  disabled={scheduleSearch?.value === "now"}
                                  timeIntervals={5}
                                  autoComplete="off"
                                  isClearable={true}
                                  timeCaption={t("Time")}
                                  timeFormat="HH:mm"
                                  value={
                                    scheduleSearch?.value === "now"
                                      ? ""
                                      : (timeSearch as any)
                                  }
                                  locale={i18n?.language === "ko" ? "ko" : "en"}
                                  name={`time`}
                                  onChange={(value: Date | null) =>
                                    setTimeSearch(
                                      value
                                        ? moment(new Date(value)).format("HH:mm")
                                        : ""
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={12}
                              className="hstack gap-1 mt-3 justify-content-start  justify-content-md-end"
                            >
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary me-1"
                                  onClick={searchData}
                                  disabled={isCampaignLoading}
                                >
                                  <i className="ri-search-line align-bottom me-1"></i>{" "}
                                  {t("Button Search")}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary fs-14"
                                  onClick={resetData}
                                >
                                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                  {t("Button Reset")}
                                </button>
                              </div>
                              {isHavePermissionRole(
                                ROLES_FOR_APP.CAMPAIGN_CREATE,
                                userPermissions
                              ) && (
                                  <div className="ms-4 d-none d-xxl-flex justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={() => navigate("/campaign/create")}
                                      style={{ width: "170px" }}
                                    >
                                      <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                      {t("Button Create Campaign")}
                                    </button>
                                  </div>
                                )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <div>
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle sticky-table-campaign"
                          theadClass="table-light"
                          columns={columns}
                          data={campaigns?.list?.length ? campaigns?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={campaigns?.pagination?.total}
                          customPageCount={Math.ceil(
                            Number(campaigns?.pagination?.total) /
                            Number(campaigns?.pagination?.limit)
                          )}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{
                            sort_by: query.sort_by,
                            order_by: query.order_by,
                          }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isCampaignLoading}
                        />
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </Container>
        <Modal
          isOpen={!!previewDetail}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={onClosePreviewDetailClick}
          keyboard={true}
        >
          <ModalHeader toggle={onClosePreviewDetailClick}>
            {t("Detail")}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="tab-content border border-bottom-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_id")}
                                </td>
                                <td>{previewDetail?.id}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_campaignName")}
                                </td>
                                <td>{previewDetail?.name}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_title")}
                                </td>
                                <td>{previewDetail?.push_title}</td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_content")}
                                </td>
                                <td>{previewDetail?.push_content}</td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_imageURL")}
                                </td>
                                <td>
                                  {previewDetail?.image_url && (
                                    <Link
                                      className="text-normal"
                                      to={previewDetail?.image_url || ""}
                                      target="_blank"
                                    >
                                      <img
                                        src={previewDetail?.image_url}
                                        className="flex-shrink-0 rounded"
                                        style={{
                                          width: "40px",
                                          height: "auto",
                                          maxHeight: "40px",
                                          objectFit: "cover",
                                        }}
                                        alt="notification"
                                      />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_url")}
                                </td>
                                <td>
                                  <Link
                                    className="text-normal"
                                    to={
                                      String(
                                        previewDetail?.push_url || ""
                                      ).includes("https://")
                                        ? previewDetail?.push_url || ""
                                        : `https://${previewDetail?.url}`
                                    }
                                    target="_blank"
                                  >
                                    {previewDetail?.push_url || ""}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_scheduleType")}
                                </td>
                                <td>{t(previewDetail?.schedule)}</td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_scheduleValue")}
                                </td>
                                <td>{previewDetail?.date}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_scheduleTime")}
                                </td>
                                <td>{previewDetail?.time}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_status")}
                                </td>
                                <td>
                                  <span
                                    className={`badge bg-${COLORS_STATUS_CAMPAIGN?.[
                                      `${previewDetail?.status}`
                                    ]
                                      }`}
                                  >{`${t(
                                    `Status_Campaign_${previewDetail?.status}`
                                  )}`}</span>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Click Tag")}
                                </td>
                                <td>
                                  {previewDetail?.hashtags?.map((item: any) => (
                                    <span className="tag-style">{item}</span>
                                  ))}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Target Tag")}
                                </td>
                                <td>
                                  {previewDetail?.targettags?.map(
                                    (item: any) => (
                                      <span className="tag-style">{item}</span>
                                    )
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>{t("Media")}</td>
                                <td>
                                  <div className="text-start">
                                    {/* <div className="text-start">
                                      {previewDetail?.media?.name}
                                    </div> */}
                                    {/* <Link className='text-normal' to={String(previewDetail?.media?.domain || '').includes('https://') ? (previewDetail?.media?.domain || '') : `https://${previewDetail?.media?.domain}`} target="_blank">
                                      {previewDetail?.media?.domain || ''}
                                    </Link> */}
                                    {showListMedia(previewDetail?.media || [])}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>{t("Sent")}</td>
                                <td>
                                  <div>
                                    {formatNumberWithCommas(
                                      previewDetail?.total_send
                                    )}{" "}
                                    /{" "}
                                    {formatNumberWithCommas(
                                      previewDetail?.total_to_send
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>{t("Click")}</td>
                                <td>
                                  {formatNumberWithCommas(
                                    previewDetail?.total_clicked
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>{t("CTR")}</td>
                                <td>
                                  {formatNumberWithCommas(
                                    previewDetail?.ctr || 0
                                  )}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Created at")}
                                </td>
                                <td>{previewDetail?.created_at || ""}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12 mb-4">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-soft-secondary fs-14"
                          type="button"
                          color="light"
                          onClick={onClosePreviewDetailClick}
                        >
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t("Button Close")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={decideIsModalEditOpen()}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={handleCloseModalEdit}
        >
          <ModalHeader toggle={handleCloseModalEdit}>
            <>
              {!!isCopy
                ? t("Button Create Campaign")
                : t("Button Update Campaign")}
            </>{" "}
            <>{!isCopy && (" - " + dataCampaign?.name || "")}</>
            {/* <>{!(campaignEdit?.isCopy) && (' - ' + campaignEdit?.name || '')}</> */}
          </ModalHeader>
          <ModalBody>
            <div>
              <CampaignForm
                id={String(dataCampaign?.id || "")}
                listDomain={listDomain}
                data={dataCampaign}
                isCopy={isCopy}
                triggerRefresh={triggerRefresh}
                isModal={true}
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={!!campaignSelectViewWinner}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={() => setCampaignSelectViewWinner((prev: any) => null)}
        >
          <ModalHeader
            toggle={() => setCampaignSelectViewWinner((prev: any) => null)}
            className="pb-0"
          ></ModalHeader>
          <ModalBody className="pt-0">
            <div className="table-responsive">
              <MultiTableWinner
                totalWinners={campaignSelectViewWinner?.totalWinners || 0}
                type={campaignSelectViewWinner?.type}
                id={campaignSelectViewWinner?.id}
                exclude_existing_winners={
                  campaignSelectViewWinner?.exclude_existing_winners
                }
              />
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t("Button Delete Campaign")}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default CampaignList;
