import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>("DASHBOARD");

    // Multi Level

    // eslint-disable-next-line
    const [isCurrentState, setIsCurrentState] = useState("Dashboard");

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement;
                if (getID) getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove("twocolumn-panel");
    }, [history]);

    const menuItems: any = [
        {
            id: "dashboard",
            label: t("Dashboards"),
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: "/dashboard",
            click: function (e: any) {
                setMenuActive((prev) => "DASHBOARD");
                setIsCurrentState((prev) => "Dashboard");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "DASHBOARD",
            isShow: isHavePermissionRole(
                ROLES_FOR_APP.DASHBOARD_VIEW,
                userPermissions
            ),
            // subItems: []
        },
        {
            id: "multilevel4",
            label: t("Media"),
            icon: <FeatherIcon icon="command" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "MEDIA");
                setIsCurrentState((prev) => "Media");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "MEDIA",
            isShow: isHavePermissionRole(
                [ROLES_FOR_APP.MEDIA_LIST, ROLES_FOR_APP.DEVICE_LIST],
                userPermissions
            ),
            subItems: [
                {
                    id: "media",
                    label: t("Media"),
                    link: "/media/list",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.MEDIA_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "device",
                    label: t("Subscription"),
                    link: "/media/subscription",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.DEVICE_LIST,
                        userPermissions
                    ),
                },
            ],
        },
        {
            id: "multilevel2",
            label: t("Campaign"),
            icon: <FeatherIcon icon="codesandbox" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "CAMPAIGN");
                setIsCurrentState((prev) => "Campaign");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "CAMPAIGN",
            isShow: isHavePermissionRole(
                [ROLES_FOR_APP.CAMPAIGN_LIST, ROLES_FOR_APP.NEWS_LIST],
                userPermissions
            ),
            subItems: [
                {
                    id: "campaign-create",
                    label: t("Create Campaign"),
                    link: "/campaign/create",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.CAMPAIGN_CREATE,
                        userPermissions
                    ),
                },
                {
                    id: "campaign-list",
                    label: t("Campaign"),
                    link: "/campaign/list",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.CAMPAIGN_LIST,
                        userPermissions
                    ),
                },
            ],
        },
        {
            id: "multilevel5",
            label: t("Statistics"),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "STATISTICS");
                setIsCurrentState((prev) => "Statistics");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "STATISTICS",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.NEWS_STATISTICS_LIST,
                    ROLES_FOR_APP.NEWS_LOG_LIST,
                    ROLES_FOR_APP.MEDIA_DAILY_STATISTICS,
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "notification-log",
                    label: t("Notification Log"),
                    link: "/statistics/notification-log",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.MEDIA_STATISTICS_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "click-log",
                    label: t("Click Log"),
                    link: "/statistics/click-log",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.MEDIA_STATISTICS_LIST,
                        userPermissions
                    ),
                },
                // {
                //   id: "un-subscription-log",
                //   label: t("UnSubscriptions Log"),
                //   link: "/statistics/un-subscription-log",
                //   isShow: isHavePermissionRole(
                //     ROLES_FOR_APP.MEDIA_STATISTICS_LIST,
                //     userPermissions
                //   ),
                // },
                {
                    id: "daily-statistics",
                    label: t("Daily Statistics"),
                    link: "/statistics/daily-statistics",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.MEDIA_DAILY_STATISTICS,
                        userPermissions
                    ),
                },
                {
                    id: "media-statistics",
                    label: t("Media Statistics"),
                    link: "/statistics/media-statistics",
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.MEDIA_MEDIA_STATISTICS,
                        userPermissions
                    ),
                },
                {
                    id: "tag-statistics",
                    label: t("Tag Statistics"),
                    link: "/statistics/tag-statistics",
                    isShow: true,
                },
                {
                    id: "user-statistics",
                    label: t("User Statistics"),
                    link: "/statistics/user-statistics",
                    isShow: true,
                },
            ],
        },
        // {
        //     id: "multilevel6",
        //     label: t('User'),
        //     icon: <FeatherIcon icon="users" className="icon-dual" />,
        //     link: "/#",
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setMenuActive((prev) => 'USER');
        //         setIsCurrentState((prev) => "User");
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: (menuActive === 'USER'),
        //     isShow: isHavePermissionRole([ROLES_FOR_APP.USER_LIST, ROLES_FOR_APP.ROLE_LIST], userPermissions),
        //     subItems: [
        //         {
        //             id: "user",
        //             label: t('User'),
        //             link: "/user/list",
        //             isShow: isHavePermissionRole(ROLES_FOR_APP.USER_LIST, userPermissions),
        //         },
        //         {
        //             id: "role-list",
        //             label: t('Role'),
        //             link: "/user/role",
        //             isShow: isHavePermissionRole(ROLES_FOR_APP.ROLE_LIST, userPermissions),
        //         },
        //     ],
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
