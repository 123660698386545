import { IMedia } from "api/types/_media";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import BreadCrumb from "components/Common/BreadCrumb";
import CopyWrapper from "components/Common/CopyWrapper";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import { deleteDomain } from "store/domain/thunk";
import {
  getScriptTrackingMedia,
  getDomains as onGetDomains,
} from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import MediaForm from "../MediaForm/MediaForm";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "subscriptions";

registerLocale("en", en);
registerLocale("ko", ko);

const MediaList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    name: withDefault(StringParam, ""),
  });

  const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataDomain, setDataDomain] = useState<any>(null);
  const [linkImage, setLinkImage] = useState<string>("");

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] =
    useState<IMedia | null>(null);

  const [keyScriptTrackingLoading, setKeyScriptTrackingLoading] = useState<
    number | null
  >(null);
  const [isOpenCopyScriptTracking, setIsOpenCopyScriptTracking] =
    useState<boolean>(false);
  const [scriptTracking, setScriptTracking] = useState<string>("");

  // Inside your component

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Domain;

  const DomainProperties = createSelector(selectLayoutState, (state) => ({
    domains: state.domains,
    isDomainSuccess: state.isDomainSuccess,
    isDomainLoading: state.isDomainLoading,
    error: state.error,
  }));

  // Inside your component
  const { domains, isDomainLoading } = useSelector(DomainProperties);

  useEffect(() => {
    dispatch(onGetDomains(query));
    //eslint-disable-next-line
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      name: nameSearch || "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    };

    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      name: "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    };
    setQuery(queryNew, "push");
    setNameSearch((_prev) => "");
  };

  const triggerRefresh = () => {
    setIsModalOpen(false);
    setDataDomain(null);
    dispatch(onGetDomains(query));
  };

  const handleOnEditDomain = (item: any) => {
    setIsModalOpen(true);
    setDataDomain({ ...item });
  };

  // Begin::Delete
  const handleConfirmDelete = (item: IMedia) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteDomain(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetDomains(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  };
  // End::Delete

  const handleGetScriptTrackingMedia = async (item: any | null) => {
    if (item?.id) {
      try {
        setKeyScriptTrackingLoading((_prev) => Number(item?.id));
        const response: any = await getScriptTrackingMedia(item?.id);
        if (response?.data) {
          setKeyScriptTrackingLoading((_prev) => null);
          setScriptTracking((_prev) => response?.data || "");
          setIsOpenCopyScriptTracking((_prev) => true);
        } else {
          setKeyScriptTrackingLoading((_prev) => null);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setKeyScriptTrackingLoading((_prev) => null);
        toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  };

  const checkImageFormat = (filename: any) => {
    if (filename) {
      const fileExtension = filename.split(".").pop().toLowerCase();

      if (fileExtension === "png") {
        return "#eaeaea";
      } else {
        return "transparent";
      }
    } else return "transparent";
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Icon"),
        accessor: "icon_url",
        thClass: "justify-content-center",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <div className="w-100 text-center">
              {cell?.value ? (
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    maxWidth: "80px",
                    maxHeight: "80px",
                  }}
                  className="m-auto position-relative"
                  onClick={() => setLinkImage((prev) => cell?.value)}
                >
                  <img
                    style={{ backgroundColor: checkImageFormat(cell?.value) }}
                    src={cell?.value}
                    className="flex-shrink-0 rounded h-100 w-100 cursor-pointer"
                    alt="chart"
                  />
                </div>
              ) : (
                <i className="ri-image-line" style={{ fontSize: "32px" }}></i>
              )}
            </div>
          </>
        ),
      },
      {
        Header: t("Media Name"),
        accessor: "name",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => {
          return <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>;
        },
      },
      {
        Header: t("Domain"),
        accessor: "domain",
        filterable: true,
        sortable: true,
        thWidth: 160,
        Cell: (cell: any) => {
          if (cell?.value) {
            const valueConvertArr = cell?.value.split(",");
            const show: any[] = [];
            //eslint-disable-next-line
            valueConvertArr.map((value: any) => {
              show.push(
                <div>
                  <Link
                    className="text-normal"
                    to={
                      String(value).includes("https://")
                        ? value
                        : `https://${value}`
                    }
                    target="_blank"
                  >
                    {value}
                  </Link>
                </div>
              );
            });

            return (
              <div
                className="d-flex"
                style={{
                  flexDirection: "column",
                  gap: "2px",
                  minWidth: "max-content",
                }}
              >
                {show}
              </div>
            );
          } else {
            return null;
          }
        },
      },
      {
        Header: t("WEB_description"),
        accessor: "description",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return <span>{cell?.value}</span>;
        },
      },
      {
        Header: t("WEB_subscriptionCount"),
        accessor: "subscriptions",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className="text-end" style={{ minWidth: "100px" }}>
                {formatNumberWithCommas(item?.total_subscription || 0)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("WEB_clickRate"),
        accessor: "click_rates",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className="text-end" style={{ minWidth: "100px" }}>
                {formatNumberWithCommas(item?.click_rate || 0)}%
              </div>
            </>
          );
        },
      },
      {
        Header: t("WEB_reachRate"),
        accessor: "reach_rates",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className="text-end" style={{ minWidth: "100px" }}>
                {formatNumberWithCommas(item?.reach_rate || 0)}%
              </div>
            </>
          );
        },
      },
      {
        Header: t("Created at media"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          return <div className="text-end" style={{ minWidth: '100px' }}>{cell?.value}</div>;
        },
      },
      {
        Header: t("Button Action"),
        thClass: "justify-content-center",
        thWidth: 130,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0"
              style={{ width: "112px" }}
            >
              {isHavePermissionRole(
                ROLES_FOR_APP.MEDIA_UPDATE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Update Media")}
                    id={`update-md-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`update-md-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOnEditDomain(item);
                        }}
                      >
                        <i className="ri-pencil-fill align-bottom "></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
              {isHavePermissionRole(
                ROLES_FOR_APP.NEWS_SCRIPT_TRACKING,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Get Script Tracking Media")}
                    id={`tracking-ns-${item?.id}`}
                  >
                    <li
                      className=" list-inline-item me-0"
                      id={`tracking-ns-${item?.id}`}
                    >
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleGetScriptTrackingMedia(item);
                        }}
                      >
                        {String(keyScriptTrackingLoading) ===
                          String(cell?.row?.id) ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          <i className="ri-code-fill align-bottom"></i>
                        )}
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
              {isHavePermissionRole(
                ROLES_FOR_APP.MEDIA_DELETE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Delete Media")}
                    id={`detail-cp-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleConfirmDelete(item);
                        }}
                      >
                        <i className="ri-delete-bin-3-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
            </ul>
          );
        },
      },
    ],
    //eslint-disable-next-line
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.title = `${t("Statistics")} - ${t(
      "Media Statistics"
    )} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDataDomain(null);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseImageClick = (e: any) => {
    setLinkImage((_prev) => "");
  };

  function onCloseCopyScriptTrackingClick() {
    setScriptTracking("");
    setIsOpenCopyScriptTracking((_prev) => !_prev);
  }

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast(`${t("Copied Successfully")}`, CONFIG_OPTION_TOAST_NORMAL);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Media")} pageTitle={t("Media")} />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.MEDIA_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <Row>
                        <Col
                          sm={12}
                          className="text-end mb-2 mb-md-0 d-block d-md-none"
                        >
                          <div className="d-flex flex g-4 align-items-end align-items-md-end mb-2 mt-2">
                            <Card
                              className="card-animate mb-0 me-0 me-md-4 bg-primary-subtle text-primary border-0"
                              style={{ width: "200px" }}
                            >
                              <CardBody>
                                <div className="d-flex align-items-center ">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-primary text-truncate mb-0 text-start">
                                      {t("Total")}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                      <span className="counter-value text-primary">
                                        <CountUp
                                          start={0}
                                          end={domains?.pagination?.total || 0}
                                          duration={1}
                                        />
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="w-100 d-flex flex-column align-items-end">
                              {isHavePermissionRole(
                                ROLES_FOR_APP.MEDIA_CREATE,
                                userPermissions
                              ) && (
                                  <button
                                    type="button"
                                    className="btn btn-success ms-2 mt-2"
                                    onClick={() => navigate("/campaign/create")}
                                    style={{ width: "170px" }}
                                  >
                                    <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                    {t("Button Create Campaign")}
                                  </button>
                                )}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-xl-end mb-2 mt-2">
                        <Card
                          className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0  d-md-block d-none"
                          style={{ width: "200px" }}
                        >
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                                  {t("Total")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isDomainLoading ? (
                                      <Spinner size="sm"></Spinner>
                                    ) : (
                                      <CountUp
                                        start={0}
                                        end={domains?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <Row className="g-4 align-items-center mb-2 mb-md-0 mt-0">
                            <Col md={12} lg={12} xl={4} className="mt-3">
                              <LabelWrapper label={t('Media Name')} isShow={!!nameSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("Media Name")}...`}
                                  value={nameSearch}
                                  onChange={(e) => setNameSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col xs={12} sm={6} lg={6} xl={4} className="mt-3">
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isDomainLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t("Button Search")}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t("Button Reset")}
                              </button>
                            </Col>

                            {isHavePermissionRole(
                              ROLES_FOR_APP.MEDIA_CREATE,
                              userPermissions
                            ) && (
                                <Col
                                  xs={12}
                                  sm={6}
                                  lg={6}
                                  xl={4}
                                  className="mt-3 d-flex justify-content-end d-md-flex d-none"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-success d-xxl-block"
                                    onClick={handleOpenModal}
                                    style={{ minWidth: "170px" }}
                                  >
                                    <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                    {t("Button Create Media")}
                                  </button>
                                </Col>
                              )}
                          </Row>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <div>
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle"
                          theadClass="table-light"
                          columns={columns}
                          data={domains?.list?.length ? domains?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={domains?.pagination?.total}
                          customPageCount={Math.ceil(
                            Number(domains?.pagination?.total) /
                            Number(domains?.pagination?.limit)
                          )}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{
                            sort_by: query.sort_by,
                            order_by: query.order_by,
                          }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isDomainLoading}
                        />
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
          <Modal
            isOpen={isModalOpen}
            centered={true}
            scrollable={true}
            size="lg"
          >
            <ModalHeader toggle={handleCloseModal}>
              {dataDomain && dataDomain.id
                ? t("Button Update Media")
                : t("Button Create Media")}
            </ModalHeader>
            <ModalBody className="py-3 px-5">
              <MediaForm
                close={handleCloseModal}
                triggerRefresh={triggerRefresh}
                data={dataDomain}
                id={dataDomain ? dataDomain.id : null}
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={!!linkImage}
            centered={true}
            size="sm"
            scrollable={true}
            toggle={onCloseImageClick}
            keyboard={true}
          >
            <ModalBody className="">
              <img
                style={{ backgroundColor: checkImageFormat(linkImage) }}
                src={linkImage || ""}
                className="flex-shrink-0 rounded w-100"
                alt="chart"
              />
            </ModalBody>
          </Modal>
        </Container>
        <ModalConfirm
          header={t("Button Delete Media")}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <Modal
          isOpen={isOpenCopyScriptTracking}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={onCloseCopyScriptTrackingClick}
          keyboard={true}
        >
          <ModalHeader toggle={onCloseCopyScriptTrackingClick}>
            {t("Script Tracking Media")}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="m-0">
                      <div className="my-3">
                        <div
                          className="p-4"
                          style={{
                            color: "var(--vz-success)",
                            backgroundColor: "var(--vz-dark)",
                          }}
                        >
                          {scriptTracking || ""}
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-primary fs-14"
                          type="button"
                          color="light"
                          onClick={() =>
                            copyTextToClipboard(scriptTracking || "")
                          }
                        >
                          <i className="ri-file-copy-line align-bottom me-1"></i>
                          {t("Button Get Script Tracking Media")}
                        </button>
                        <button
                          className="btn btn-soft-secondary fs-14"
                          type="button"
                          color="light"
                          onClick={onCloseCopyScriptTrackingClick}
                        >
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t("Button Close")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default MediaList;
