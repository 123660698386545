const ROLES_FOR_APP = {
  DASHBOARD_VIEW: "view-dashboard",

  LOG_LIST_USER: "list-user-log",
  LOG_LIST_ADMIN: "list-admin-log",
  LOG_NEWS_LIST: "",

  ADMIN_LIST: "list-admin",
  ADMIN_CREATE: "create-admin",
  ADMIN_UPDATE: "update-admin",
  ADMIN_DELETE: "remove-admin",

  POST_LIST: "list-post",
  POST_RESTORE: "restore-post",
  POST_REMOVE: "remove-post",
  POST_DELETE: "delete-post",

  NOTIFICATION_PUSH_CREATE: "create-push",
  NOTIFICATION_PUSH_REMOVE: "remove-push",
  NOTIFICATION_PUSH_LIST: "list-push",

  NOTIFICATION_LOG_LIST: "list-logs",
  NOTIFICATION_LOG_TYPE: "type",

  SETTING_CONFIG_LIST: "list-config",
  SETTING_CONFIG_RESTORE: "restore-config",
  SETTING_CONFIG_REMOVE: "remove-config",
  SETTING_CONFIG_DELETE: "delete-config",

  NAVER_KEYWORD_LIST: "naver-keyword",
  NAVER_KEYWORD_CREATE: "naver-keyword",
  NAVER_KEYWORD_UPDATE: "naver-keyword",
  NAVER_KEYWORD_DELETE: "naver-keyword",

  CAR_KEYWORD_LIST: "car-keyword",
  CAR_KEYWORD_CREATE: "car-keyword",
  CAR_KEYWORD_UPDATE: "car-keyword",
  CAR_KEYWORD_DELETE: "car-keyword",
  CAR_KEYWORD_IMPORT: "car-keyword",

  CAR_CATEGORY_LIST: "car-keyword",
  CAR_CATEGORY_CREATE: "car-keyword",
  CAR_CATEGORY_UPDATE: "car-keyword",
  CAR_CATEGORY_DELETE: "car-keyword",
  CAR_CATEGORY_IMPORT: "car-keyword",

  MOVIE_KEYWORD_LIST: "movie-keyword",
  MOVIE_KEYWORD_CREATE: "movie-keyword",
  MOVIE_KEYWORD_UPDATE: "movie-keyword",
  MOVIE_KEYWORD_DELETE: "movie-keyword",
  MOVIE_KEYWORD_IMPORT: "movie-keyword",

  MOVIE_CATEGORY_LIST: "movie-keyword",
  MOVIE_CATEGORY_CREATE: "movie-keyword",
  MOVIE_CATEGORY_UPDATE: "movie-keyword",
  MOVIE_CATEGORY_DELETE: "movie-keyword",
  MOVIE_CATEGORY_IMPORT: "movie-keyword",

  EXCHANGE_RATE_LIST: "exchange-rate",
  EXCHANGE_RATE_CREATE: "exchange-rate",
  EXCHANGE_RATE_UPDATE: "exchange-rate",
  EXCHANGE_RATE_DELETE: "exchange-rate",

  EXCHANGE_RATE_CATEGORY_LIST: "exchange-rate",
  EXCHANGE_RATE_CATEGORY_CREATE: "exchange-rate",
  EXCHANGE_RATE_CATEGORY_UPDATE: "exchange-rate",
  EXCHANGE_RATE_CATEGORY_DELETE: "exchange-rate",

  STOCK_KEYWORD_LIST: "car-keyword",
  STOCK_KEYWORD_CREATE: "car-keyword",
  STOCK_KEYWORD_UPDATE: "car-keyword",
  STOCK_KEYWORD_DELETE: "car-keyword",
  STOCK_KEYWORD_IMPORT: "car-keyword",

  STOCK_CATEGORY_LIST: "car-keyword",
  STOCK_CATEGORY_CREATE: "car-keyword",
  STOCK_CATEGORY_UPDATE: "car-keyword",
  STOCK_CATEGORY_DELETE: "car-keyword",
  STOCK_CATEGORY_IMPORT: "car-keyword",

  TREND_INDEX_CATEGORY_LIST: "trend-index-category",
  TREND_INDEX_CATEGORY_CREATE: "trend-index-category",
  TREND_INDEX_CATEGORY_UPDATE: "trend-index-category",
  TREND_INDEX_CATEGORY_DELETE: "trend-index-category",

  TREND_INDEX_LIST: "trend-index",
  TREND_INDEX_CREATE: "trend-index",
  TREND_INDEX_UPDATE: "trend-index",
  TREND_INDEX_DELETE: "trend-index",

  TEMPLATE_TITLE_LIST: "list-news-title",
  TEMPLATE_TITLE_CREATE: "create-news-title",
  TEMPLATE_TITLE_UPDATE: "update-news-title",
  TEMPLATE_TITLE_DELETE: "remove-news-title",

  TEMPLATE_BODY_LIST: "list-news-content",
  TEMPLATE_BODY_CREATE: "create-news-content",
  TEMPLATE_BODY_UPDATE: "update-news-content",
  TEMPLATE_BODY_DELETE: "remove-news-content",

  INFO_GRAPHIC_LIST: "list-info-graphic",
  INFO_GRAPHIC_CREATE: "create-info-graphic",
  INFO_GRAPHIC_UPDATE: "update-info-graphic",
  INFO_GRAPHIC_DELETE: "remove-info-graphic",

  VARIABLE_DEFINITION_LIST: "list-variable-definition",
  VARIABLE_DEFINITION_CREATE: "create-variable-definition",
  VARIABLE_DEFINITION_UPDATE: "update-variable-definition",
  VARIABLE_DEFINITION_DELETE: "remove-variable-definition",

  CAMPAIGN_LIST: "list-campaign",
  CAMPAIGN_CREATE: "create-campaign",
  CAMPAIGN_UPDATE: "update-campaign",
  CAMPAIGN_DELETE: "delete-campaign",
  CAMPAIGN_SAVE: "create-campaign",
  CAMPAIGN_DOWNLOAD: "list-campaign",

  MEDIA_LIST: "list-admin",
  MEDIA_CREATE: "create-admin",
  MEDIA_UPDATE: "update-admin",
  MEDIA_DELETE: "remove-admin",
  MEDIA_REFRESH_KEY: "refersh-key",

  SETTLEMENT_LIST: "list-admin",
  SETTLEMENT_CREATE: "create-admin",
  SETTLEMENT_UPDATE: "update-admin",
  SETTLEMENT_DELETE: "delete-admin",

  NEWS_LIST: "list-news",
  NEWS_CREATE: "create-news",
  NEWS_UPDATE: "update-news",
  NEWS_DELETE: "delete-news",
  NEWS_DOWNLOAD_IMAGE: "list-news",
  NEWS_DOWNLOAD_WORD: "list-news",
  NEWS_PREVIEW: "list-news",
  NEWS_REFRESH: "update-news",
  NEWS_SCRIPT_TRACKING: "generate-script-news",
  NEWS_SET_CONFIRM: "update-news", //set-confirm
  NEWS_DOWNLOAD_FILE: "update-news", //download-file

  NEWS_LOG_LIST: "list-news-log",
  NEWS_LOG_CREATE: "create-news-log",
  NEWS_LOG_UPDATE: "update-news-log",
  NEWS_LOG_DELETE: "remove-news-log",

  NEWS_STATISTICS_LIST: "list-news-statistics",
  NEWS_STATISTICS_CREATE: "create-news-statistics",
  NEWS_STATISTICS_UPDATE: "update-news-statistics",
  NEWS_STATISTICS_DELETE: "remove-news-statistics",

  MEDIA_LOG_LIST: "list-news-statistics", //
  MEDIA_STATISTICS_LIST: "list-news-statistics", //
  KEYWORD_INFLOW_LIST: "list-news-statistics", //
  KEYWORD_STATISTICS_LIST: "list-news-statistics", //
  CAMPAIGN_STATISTICS_LIST: "list-news-statistics", //
  CATEGORY_STATISTICS_LIST: "list-news-statistics", //
  MEDIA_DAILY_STATISTICS: "daily-statistics", //
  MEDIA_MEDIA_STATISTICS: "media-statistics", //

  ROLE_LIST: "list-role",
  ROLE_CREATE: "create-role",
  ROLE_UPDATE: "update-role",
  ROLE_DELETE: "remove-role",

  USER_LIST: "list-user",
  USER_CREATE: "create-user",
  USER_UPDATE: "update-user",
  USER_DELETE: "remove-user",

  DEVICE_LIST: "list-device",
};

const isHavePermissionRole = (
  keyRoles: string | string[],
  permissions: { id: string | number; name: string }[] = []
): boolean => {
  return true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };
