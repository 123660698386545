import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
import { getDashboard as onGetDashboard } from "store/dashboard/thunk";

const Widgets = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>();

  const dispatch: any = useDispatch();

  // Inside your component

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(onGetDashboard({}));
        if (response?.payload?.data) {
          setData(response?.payload?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch]);

  const dataDashboard = [
    {
      id: 1,
      cardColor: "secondary",
      label: t("DashBoard Campaign"),
      badge: "ri-arrow-right-down-line",
      percentage: data?.campaigns?.last_created ?? "0000-00-00 00:00:00",
      counter: data?.campaigns?.count,
      link: "View all orders",
      icon: "bx bx-bookmarks",
      decimals: 0,
      prefix: "",
      suffix: "",
      bgcolor: "success-subtle text-success",
    },
    {
      id: 2,
      cardColor: "primary",
      label: t("DashBoard Domain"),
      badge: "ri-arrow-right-down-line",
      percentage: data?.domains?.last_created ?? "0000-00-00 00:00:00",
      counter: data?.domains?.count,
      link: "View all orders",
      icon: "bx bx-buildings",
      decimals: 0,
      prefix: "",
      suffix: "",
      bgcolor: "primary-subtle text-primary",
    },
    {
      id: 2,
      cardColor: "primary",
      label: t("DashBoard Subscription"),
      badge: "ri-arrow-right-down-line",
      percentage: data?.subscription?.last_created ?? "0000-00-00 00:00:00",
      counter: data?.subscription?.count,
      link: "View all orders",
      icon: "bx bx-user",
      decimals: 0,
      prefix: "",
      suffix: "",
      bgcolor: "primary-subtle text-primary",
    },
  ];

  return (
    <React.Fragment>
      {dataDashboard?.map((item: any, key: any) => (
        <Col sm={6} md={6} xl={3} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix={item.prefix}
                        suffix={item.suffix}
                        separator={item.separator}
                        end={item.counter}
                        decimals={item.decimals}
                        duration={2}
                      />
                    </span>
                  </h4>
                  <span className="">
                    {t("Updated")}: {item.percentage}
                  </span>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={"avatar-title rounded fs-3 bg-" + item.bgcolor}
                  >
                    <i className={`${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
