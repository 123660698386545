import { IDomain } from "api/types/_domain";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface SearchFilterDomainProps {
  name?: string;
  dataList?: IDomain[];
  initialValue?: Option[] | Option | null | undefined;
  filterByTypeId?: string | number;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  id?: string;
  isDisabled?: any;
}

const SearchFilterDomain = ({
  name = "",
  initialValue = [],
  dataList = [],
  filterByTypeId = "",
  onChangeSelect,
  placeholder,
  isMulti = true,
  isClearable = false,
  id = "",
  isDisabled = false,
}: SearchFilterDomainProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<Option[]>([]);
  //eslint-disable-next-line
  const [keywordSearch, setKeywordSearch] = useState<string>("");
  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result =
      dataList?.map((item) => ({
        label: item?.name,
        value: String(item?.id || ""),
        website: item?.website || "",
      })) || ([] as Option[]);
    setOptions((_prev: any) => result);
    if (filterByTypeId) {
      const resultFilterByTypeId = dataList?.map((item) => ({
        label: item?.name,
        value: String(item?.id || ""),
      }));
      setOnChangeOption && setOnChangeOption(resultFilterByTypeId);
    }
    //eslint-disable-next-line
  }, [JSON.stringify(dataList), filterByTypeId]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        name={name}
        isClearable={isClearable}
        value={initialValue}
        onInputChange={(e: any) => setKeywordSearch(e)}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={
          <div>{isMulti ? t("Select Medias") : t("Select Media")}...</div>
        }
        loadingMessage={() => <div>{t("Loading")}...</div>}
        noOptionsMessage={() => <div>{t("No Options")}</div>}
        formatOptionLabel={(option: any) => (
          <div className="d-flex">
            {option?.label}
            {/* <div className="op ms-2">
              {`${option?.website ? option?.website : ''}`}
            </div> */}
          </div>
        )}
        // formatOptionLabel={(option: any) => (<div className="d-flex">{option?.label}</div>)}
        className="search-filter-media"
        classNamePrefix="name-prefix"
        id={id}
        isDisabled={isDisabled}
      />
    </React.Fragment>
  );
};

export default SearchFilterDomain;
