import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import dashboardApi from "api/dashboardApi";
import { formatQueryParams } from "helpers/format";

export const getDashboard = createAsyncThunk(
  "dashboard",
  async (params: any = {}) => {
    try {
      return await dashboardApi.dashboard(formatQueryParams(params));
    } catch (error) {
      return error;
    }
  }
);
