import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import roleApi from "api/roleApi";
import { formatQueryParams } from "helpers/format";

export const getRoles = createAsyncThunk("Roles", async (params: any = {}) => {
  try {
    const response = await roleApi.roles(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllRoles = async (params: any = {}) => {
  try {
    const response = await roleApi.allRoles(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRole = async (id: string | number, params: any = {}) => {
  try {
    const response = await roleApi.getRole(id, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const postRole = async (data: any = {}) => {
  try {
    const response = await roleApi.postRole(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putRole = async (id: string | number, data: any = {}) => {
  try {
    const response = await roleApi.putRole(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteRole = async (id: string | number, data: any = {}) => {
  try {
    const response = await roleApi.deleteRole(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllPermissions = async (params: any = {}) => {
  try {
    const response = await roleApi.allPermissions(params);
    return response;
  } catch (error) {
    return error;
  }
};
