import { t } from "i18next";
import WinnerList from "pages/Campaign/Components/WinnerList";
import { useEffect, useState } from "react";
import ApplicantList from "../ApplicantList";

export default function MultiTableWinner({
  type,
  id,
  exclude_existing_winners,
  totalWinners,
}: any) {
  const [tabSelect, setTabSelect] = useState("0");

  const [summary, setSummary] = useState<any>({
    totalWinner: 0,
    totalWinnerSelected: 0,
    totalApplicant: 0,
  });

  const [triggerData, setTriggerData] = useState(0);

  const updateSummary = (data: any) => {
    if (data?.type === "1") {
      setSummary((prev: any) => ({
        ...prev,
        totalWinner: data?.data?.totalWinner || 0,
      }));
    }
    if (data?.type === "2") {
      setSummary((prev: any) => ({
        ...prev,
        totalWinnerSelected: data?.data?.totalWinnerSelected || 0,
      }));
    }
    if (data?.type === "applicant") {
      setSummary((prev: any) => ({
        ...prev,
        totalApplicant: data?.data?.totalApplicant || 0,
      }));
    }
  };

  return (
    <div className="card mt-3 mb-0">
      <div className="card-body">
        <ul className="nav nav-tabs nav-border-top nav-border-top-primary mb-3 nav nav-tabs">
          <li className="nav-item" onClick={() => setTabSelect("0")}>
            <a
              className={`nav-link ${tabSelect === "0" ? "active" : ""}`}
              style={{ cursor: "pointer" }}
            >
              {t("Total first-come, first-served winner")}
              <div
                className={`badge ${
                  summary?.totalWinner > 0 ? "bg-primary" : "bg-danger"
                }  ms-2`}
              >
                {summary?.totalWinner} {" / "}
                {totalWinners}
              </div>
            </a>
          </li>
          <li className="nav-item" onClick={() => setTabSelect("1")}>
            <a
              className={`nav-link ${tabSelect === "1" ? "active" : ""}`}
              style={{ cursor: "pointer" }}
            >
              {t("Total winner selected through draw")}
              <div
                className={`badge ${
                  summary?.totalWinnerSelected > 0 ? "bg-primary" : "bg-danger"
                }  ms-2`}
              >
                {summary?.totalWinnerSelected} {" / "}
                {totalWinners}
              </div>
            </a>
          </li>
          <li className="nav-item" onClick={() => setTabSelect("2")}>
            <a
              className={`nav-link ${tabSelect === "2" ? "active" : ""}`}
              style={{ cursor: "pointer" }}
            >
              {t("Applicants")}
              {summary?.totalApplicant ? (
                <div className="badge bg-primary ms-2">
                  {summary?.totalApplicant}
                </div>
              ) : (
                ""
              )}
            </a>
          </li>
        </ul>
        <div className="tab-content text-muted">
          <div
            id="animation-home"
            className={`tab-pane ${tabSelect === "0" ? "active" : ""}`}
          >
            <WinnerList
              updateSummary={updateSummary}
              type="1"
              id={id}
              isReLoad={tabSelect === "0"}
              triggerData={triggerData}
            />
          </div>
          <div
            id="animation-profile"
            className={`tab-pane ${tabSelect === "1" ? "active" : ""}`}
          >
            <WinnerList
              updateSummary={updateSummary}
              type="2"
              id={id}
              isReLoad={tabSelect === "1"}
              triggerData={triggerData}
            />
          </div>
          <div
            id="animation-messages"
            className={`tab-pane ${tabSelect === "2" ? "active" : ""}`}
          >
            <ApplicantList
              updateTriggerData={() => setTriggerData((prev: any) => prev + 1)}
              updateSummary={updateSummary}
              type="2"
              id={id}
              exclude_existing_winners={exclude_existing_winners}
              isReLoad={tabSelect === "2"}
              triggerData={triggerData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
