interface ApiConfig {
  API_URL: string;
}

interface Config {
  api: ApiConfig;
  sso_newshub?: string;
  sso_redirect_url?: string;
}

const config: Config = {
  api: {
    API_URL: `${process.env.REACT_APP_API_URL}`,
  },
  sso_newshub: `${process.env.REACT_APP_SSO_NEWSHUB_LOGIN}`,
  sso_redirect_url: `${process.env.REACT_APP_SSO_REDIRECT_URL_LOGIN}`,
};

export default config;