import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IDashboard } from "./types/_dashboard";
const api = new APIClient();

const path = "/dashboard";
const dashboardApi = {
  dashboard(params: any): Promise<AxiosResponse<IDashboard, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
};
export default dashboardApi;
