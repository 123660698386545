import BreadCrumb from "components/Common/BreadCrumb";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownOption from "components/Common/DropdownOption";
import InputsRange from "components/Common/InputsRange";
import LabelWrapper from "components/Common/LabelWrapper";
import SearchFilterTag from "components/Common/SearchFilterTag";
import TableContainer from "components/Common/TableContainer";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  getHashTags,
  getUserStatistics as onGetUserStatistics,
} from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "received";

registerLocale("en", en);
registerLocale("ko", ko);

const UserStatistics = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { setDurationResponses } = useDurationResponses();
  const [openModalKeyword, setOpenModalKeyword] = useState(false);
  const [keywordsModal, setKeywordsModal] = useState<any[]>([]);
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    uuid: withDefault(StringParam, ""),
    start_received: withDefault(StringParam, ""),
    end_received: withDefault(StringParam, ""),
    start_click: withDefault(StringParam, ""),
    end_click: withDefault(StringParam, ""),
    hashtags: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    moment(query?.start_date || "", "Y-MM-DD").toDate(),
    moment(query?.end_date || "", "Y-MM-DD").toDate(),
  ]);
  //eslint-disable-next-line
  const [startDate, endDate] = dateSearch;

  //eslint-disable-next-line
  const [nameSearch, setNameSearch] = useState<string>(query?.uuid || "");

  const [listHashtag, setListHashtag] = useState<any>([]);
  const [hashtagSearch, setHashtagSearch] = useState<any | null>(
    query?.hashtags
      ? (query?.hashtags || "")
        .split(",")
        ?.map((item: any) => ({ label: item, value: item }))
      : []
  );

  const [startReceivedSearch, setStartReceivedSearch] = useState<string>(
    query?.start_received || ""
  );
  const [endReceivedSearch, setEndReceivedSearch] = useState<string>(
    query?.end_received || ""
  );

  const [startClickSearch, setStartClickSearch] = useState<string>(
    query?.start_click || ""
  );
  const [endClickSearch, setEndClickSearch] = useState<string>(
    query?.end_click || ""
  );

  // Inside your component
  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const UserProperties = createSelector(selectLayoutState, (state) => ({
    users: state.users,
    isUserSuccess: state.isUserSuccess,
    isUserLoading: state.isUserLoading,
    error: state.error,
  }));

  // Inside your component
  const { users, isUserLoading } = useSelector(UserProperties);

  useEffect(() => {
    dispatch(onGetUserStatistics(query));
    //eslint-disable-next-line
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      uuid: nameSearch || "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
      hashtags: hashtagSearch?.map((item: any) => item?.label)?.join(",") || "",
      start_received: startReceivedSearch || "",
      end_received: endReceivedSearch || "",
      start_click: startClickSearch || "",
      end_click: endClickSearch || "",
    };
    setQuery(queryNew);
  };

  const showModalKeywords = (data: any) => {
    setOpenModalKeyword(true);
    setKeywordsModal(data);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      uuid: "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
      hashtags: "",
    };
    setQuery(queryNew, "push");
    setNameSearch((_prev) => "");
    setHashtagSearch([]);
    setStartReceivedSearch("");
    setEndReceivedSearch("");
    setStartClickSearch("");
    setEndClickSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("UUID"),
        accessor: "uuid",
        filterable: true,
        sortable: true,
        thWidth: 300,
        Cell: (cell: any) => {
          return (
            <div style={{ width: "300px" }}>
              <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
            </div>
          );
        },
      },
      {
        Header: t("Received"),
        accessor: "received",
        filterable: true,
        sortable: true,
        thWidth: 100,
        thClass: "justify-content-end",
        Cell: (cell: any) => {
          return (
            <div className="text-end" style={{ width: "100px" }}>
              {cell?.value}
            </div>
          );
        },
      },
      {
        Header: t("Click"),
        accessor: "click",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end",
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <div className="text-end" style={{ width: "100px" }}>
              {formatNumberWithCommas(cell?.value)}
            </div>
          </>
        ),
      },
      {
        Header: t("CTR"),
        accessor: "ctr",
        filterable: true,
        sortable: true,
        thClass: "justify-content-end pe-3",
        thWidth: 150,
        Cell: (cell: any) => (
          <>
            <div className="text-end pe-4" style={{ width: "150px" }}>
              {formatNumberWithCommas(cell?.value || 0)}%
            </div>
          </>
        ),
      },
      {
        Header: t("Tags"),
        accessor: "hashtags",
        filterable: true,
        sortable: true,
        thWidth: 500,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const hashtags = value?.hashtags || [];
          return (
            <div style={{ minWidth: "100px", maxWidth: '900px' }} className="d-flex flex-wrap gap-2">
              {hashtags?.map((k: any, index: number) => (
                <div
                  className={`tag-style d-flex px-1 ${!!k?.name ? '' : 'd-none'}`}
                  key={index}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {k?.name} :{" "}
                  <span className="ms-1 text-danger fw-bold">{k?.count}</span>
                </div>
              ))}
            </div>
          );
        },
      },
    ],
    //eslint-disable-next-line
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [resTag]: any = await Promise.all([getHashTags()]);
        if (resTag) {
          const newData = resTag?.data?.list?.map((item: any) => ({
            value: item?.name,
            label: item?.name,
          }));
          setListHashtag(newData);
        }
      } catch (error: any) {
        return error;
      }
    };

    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t("Statistics")} - ${t(
      "User Statistics"
    )} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    setDurationResponses([
      {
        name: "User Statistics",
        time: users?.pagination?.duration || 0,
      },
    ]);
  }, [users?.pagination?.duration]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("User Statistics")}
            pageTitle={t("Statistics")}
          />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.MEDIA_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <Row>
                        <Col
                          sm={12}
                          className="text-end mb-2 mb-md-0 d-block d-md-none"
                        >
                          <div className="d-flex flex g-4 align-items-end align-items-md-end mb-2 mt-2">
                            <Card
                              className="card-animate mb-0 me-0 me-md-4 bg-primary-subtle text-primary border-0"
                              style={{ width: "200px" }}
                            >
                              <CardBody>
                                <div className="d-flex align-items-center ">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-primary text-truncate mb-0 text-start">
                                      {t("Total")}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                      <span className="counter-value text-primary">
                                        <CountUp
                                          start={0}
                                          end={users?.pagination?.total || 0}
                                          duration={1}
                                        />
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-xl-end mb-2 mt-2">
                        <Card
                          className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0  d-md-block d-none"
                          style={{ width: "200px" }}
                        >
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                                  {t("Total")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isUserLoading ? (
                                      <Spinner size="sm"></Spinner>
                                    ) : (
                                      <CountUp
                                        start={0}
                                        end={users?.pagination?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <Row className="g-4 align-items-center mb-2 mb-md-0 mt-0">
                            <Col sm={12} md={12} xl={4} xxl={3} className="mt-3">
                              <LabelWrapper
                                label={t("UUID")}
                                isShow={!!nameSearch}
                              >
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("UUID")}...`}
                                  value={nameSearch}
                                  onChange={(e) => setNameSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <LabelWrapper
                                label={t("Tags")}
                                isShow={hashtagSearch?.length > 0}
                              >
                                <DropdownOption
                                  name="format"
                                  dataList={listHashtag || []}
                                  placeholder={`${t("Tags")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={hashtagSearch || null}
                                  onChangeSelect={(e: any) => {
                                    setHashtagSearch((prev: any) => e);
                                  }}
                                  isHasOptionAll={false}
                                  isMulti
                                  isClearable={true}
                                />
                              </LabelWrapper>
                            </Col>
                          </Row>
                          <Row className="g-4 align-items-center mb-2 mb-md-0 mt-0">
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={startReceivedSearch}
                                valueTo={endReceivedSearch}
                                placeholderFrom={`${t("Received (Start)")}...`}
                                placeholderTo={`${t("Received (End)")}...`}
                                labelFrom={`${t("Received (Start)")}`}
                                labelTo={`${t("Received (End)")}`}
                                onChangeFrom={(val) =>
                                  setStartReceivedSearch(val)
                                }
                                onChangeTo={(val) => setEndReceivedSearch(val)}
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>
                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={3}
                              className="mt-0 mt-3"
                            >
                              <InputsRange
                                nameFrom="from"
                                nameTo="to"
                                valueFrom={startClickSearch}
                                valueTo={endClickSearch}
                                placeholderFrom={`${t("Click (Start)")}...`}
                                placeholderTo={`${t("Click (End)")}...`}
                                labelFrom={`${t("Click (Start)")}`}
                                labelTo={`${t("Click (End)")}`}
                                onChangeFrom={(val) => setStartClickSearch(val)}
                                onChangeTo={(val) => setEndClickSearch(val)}
                                onKeyDownFrom={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                                onKeyDownTo={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </Col>

                            <Col
                              sm={12}
                              md={12}
                              xl={4}
                              xxl={6}
                              className="mt-0 mt-3"
                            >
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isUserLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t("Button Search")}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t("Button Reset")}
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <div>
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle"
                          theadClass="table-light"
                          columns={columns}
                          data={users?.list?.length ? users?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={users?.pagination?.total}
                          customPageCount={Math.ceil(
                            Number(users?.pagination?.total) /
                            Number(users?.pagination?.limit)
                          )}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{
                            sort_by: query.sort_by,
                            order_by: query.order_by,
                          }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isUserLoading}
                        />
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={openModalKeyword}
        centered={true}
        size="xl"
        scrollable={true}
        toggle={() => {
          setOpenModalKeyword(false);
        }}
        keyboard={true}
      >
        <ModalHeader
          toggle={() => {
            setOpenModalKeyword(false);
          }}
        >
          {t("Tags")}
        </ModalHeader>
        <ModalBody className="mt-0 py-0">
          <div className="card">
            <div className="card-body">
              <div className="row g-4 w-100">
                <Col md={12} className="mt-2">
                  <div className="d-flex flex-row flex-wrap">
                    {keywordsModal?.map((item: any, index: number) => (
                      <ListGroupItem
                        key={index}
                        tag="label"
                        className="px-4 py-3 text-start d-flex gap-2"
                      >
                        <span className="text-muted">{index + 1}.</span>{" "}
                        <div
                          className={`tag-style d-flex px-1 ${!!item?.name ? "" : "d-none"
                            }`}
                          key={index}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {item?.name} :{" "}
                          <span className="ms-1 text-danger fw-bold">
                            {item?.count}
                          </span>
                        </div>
                      </ListGroupItem>
                    ))}
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UserStatistics;
