import { Option } from "api/types/_public";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import ModalConfirm from "components/Common/ModalConfirm";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../../../components/Common/TableContainer";
import {
  getApplicants,
  postRandomSelectWinner,
  postSelectWinner,
} from "../../../../store/thunks";

registerLocale("en", en);
registerLocale("ko", ko);

const ApplicantList = ({
  id,
  type,
  exclude_existing_winners,
  isReLoad,
  updateSummary,
  triggerData,
  updateTriggerData,
}: any) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const [query, setQuery]: any = useState({
    time_request: 0,
    campaign_id: id,
    page: 1,
    limit: 30,
  });

  const [isLoadingWinners, setIsLoadingWinner] = useState(false);
  const [listApplicant, setListApplicant] = useState<any>({});

  const getListApplicant = async () => {
    setIsLoadingWinner(true);
    try {
      const res: any = await getApplicants(query);
      if (res?.code === 200) {
        setListApplicant(res?.data);
        if (type === "2") {
          updateSummary({
            type: "applicant",
            data: {
              totalApplicant: res?.data?.pagination?.total,
            },
          });
        }
      }
      setIsLoadingWinner(false);
    } catch (error) {
      setIsLoadingWinner(false);
    }
  };

  useEffect(() => {
    if (isReLoad) {
      getListApplicant();
    }
  }, [query, isReLoad]);

  // Begin:: handle select table
  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const handleCheck = (
    valueCheck: Option,
    valueIndex: number | string,
    e: any = null
  ) => {
    const index = String(valueIndex);
    if (valueCheck?.value === "ALL") {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          const cloneListData: any = listApplicant?.list?.filter(
            (item: any) => !item?.is_winner
          );
          prevClone[index] = (cloneListData || [])?.map((item: any) => ({
            value: item?.subscription?._id,
            label: item?.id,
          }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter(
          (item: any) => item?.value !== valueCheck?.value
        );
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = [...prevClone[index], valueCheck];
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!optionsSelected[index]?.find(
      (x: any) => x.value === valueCheck?.value
    )?.value;
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    const dataClone = structuredClone(listApplicant?.list)?.filter(
      (item: any) => !item?.is_winner
    );
    return (
      optionsSelected[valueIndexString]?.length > 0 &&
      optionsSelected[valueIndexString]?.length === dataClone?.length
    );
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce(
      (total: number, [key, value]: any) => {
        return Number(total + (value || [])?.length);
      },
      0
    );
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce(
      (arr: Option[], [key, value]: any) => {
        return [...arr, ...(value || [])];
      },
      []
    );
  }, [optionsSelected]);

  // End:: handle select table
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirmSelect, setIsOpenConfirmSelect] =
    useState<boolean>(false);
  const [typeRandom, setTypeRandom] = useState<string>("");
  const handleConfirmSelect = (type: string = "SELECTED | RANDOMLY") => {
    setTypeRandom((_prev) => type);
    setIsOpenConfirmSelect((_prev) => true);
  };

  const onCloseConfirmSelectClick = () => {
    setIsOpenConfirmSelect((_prev) => false);
  };

  const handleActionSelect = async () => {
    if (!id && optionsSelected?.length === 0) {
      return;
    }
    try {
      setIsLoading((_prev) => true);
      const ids = (optionsSelected || [])
        .flat()
        ?.map((item: any) => item?.value);
      const response: any =
        typeRandom === "RANDOMLY"
          ? await postRandomSelectWinner(id)
          : await postSelectWinner({
              campaign_id: id,
              type: 2,
              subscription_ids: ids,
            });
      if (response?.code === 200) {
        setQuery((prev: any) => ({ ...prev, page: 1 }));
        setIsLoading((_prev) => false);
        setOptionsSelected((prev: any) => []);
        updateTriggerData && updateTriggerData();
        onCloseConfirmSelectClick();
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const isHiddenCheckHeader = listApplicant?.list?.filter((item: any) => {
    return (
      !item?.is_winner &&
      !(exclude_existing_winners && item?.is_winner_other_campaign)
    );
  });

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Key"),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: "text-start",
        thWidth: 46,

        thComponent: () => (
          <>
            <div className="form-check ">
              {isHiddenCheckHeader?.length > 0 ? (
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isCheckedAll(query?.page)}
                  onChange={(e) =>
                    handleCheck({ value: "ALL", label: "" }, query?.page, e)
                  }
                  value=""
                  id={`cell-check-all`}
                />
              ) : (
                ""
              )}

              <label
                className="form-check-label"
                htmlFor={`cell-check-all`}
              ></label>
            </div>
          </>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div
                className={`form-check ${
                  item?.is_winner_other_campaign
                    ? "row-bg-danger"
                    : item?.is_winner
                    ? "row-bg-primary"
                    : ""
                }`}
              >
                {!item?.is_winner &&
                  !(
                    exclude_existing_winners && item?.is_winner_other_campaign
                  ) && (
                    <>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="table"
                        checked={isChecked({
                          value: item?.subscription?._id,
                          label: item?.id,
                        })}
                        value={item?.id ?? ""}
                        onChange={(e) =>
                          handleCheck(
                            { value: item?.subscription?._id, label: item?.id },
                            query?.page,
                            e
                          )
                        }
                        id={`cell-check-${item?.subscription?._id ?? ""}`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`cell-check-${item?.subscription?._id ?? ""}`}
                      ></label>
                    </>
                  )}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Phone number"),
        accessor: "phone_number",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div>{item?.phone_number}</div>
            </>
          );
        },
      },
      {
        Header: t("Number of support instances"),
        accessor: "number_of_support_instances",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(cell?.value || 0)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Number of selections"),
        accessor: "number_of_selections",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(cell?.value || 0)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">{cell?.value}</div>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language, JSON.stringify(query), userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery((prev: any) => ({ ...prev, page: page + 1 }));
    // eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    getListApplicant();
  }, [triggerData]);

  return (
    <React.Fragment>
      <div>
        <Col lg={12} className="text-end mb-3 mt-3">
          <Row>
            <Col md={6}>
              <div>
                <div className="text-start fs-12 d-flex align-items-center mb-1">
                  <div
                    className="d-inline-block me-2 "
                    style={{
                      width: "14px",
                      height: "14px",
                      backgroundColor: "rgba(37, 160, 226, 0.2)",
                    }}
                  ></div>
                  {t("User is included in the winner list")}
                </div>
                <div className="text-start fs-12 d-flex align-items-center">
                  <div
                    className="d-inline-block me-2"
                    style={{
                      width: "14px",
                      height: "14px",
                      backgroundColor: "rgba(240, 101, 72, 0.2)",
                    }}
                  ></div>
                  {t(
                    "The user is included in the winner list of another campaign"
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <button
                  type="button"
                  className="btn btn-primary me-4"
                  disabled={countRowSelected <= 0}
                  onClick={() => handleConfirmSelect("SELECTED")}
                >
                  {countRowSelected > 0 && (
                    <span
                      className="position-absolute topbar-badge badge rounded-pill bg-danger"
                      style={{ transform: "translate(0%, -70%)" }}
                    >
                      {formatNumberWithCommas(countRowSelected)}
                      <span className="visually-hidden">
                        total keywords selected
                      </span>
                    </span>
                  )}
                  {t("Button Selected")}
                </button>

                <button
                  type="button"
                  className="btn btn-success fs-14"
                  onClick={() => handleConfirmSelect("RANDOMLY")}
                >
                  {t("Button Randomly selected")}
                </button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={12} className="px-0">
          <Card id="leadsList">
            <CardBody className="pt-3">
              <TableContainer
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="align-middle"
                theadClass="table-light"
                columns={columns}
                data={listApplicant?.list?.length ? listApplicant?.list : []}
                customPageSize={query.limit}
                customPageIndex={query.page - 1}
                totalRecords={listApplicant?.pagination?.total}
                customPageCount={Math.ceil(
                  Number(listApplicant?.pagination?.total) /
                    Number(listApplicant?.pagination?.limit)
                )}
                handleChangePage={handleChangePage}
                manualSorting={true}
                sorting={{
                  sort_by: query.sort_by,
                  order_by: query.order_by,
                }}
                handleChangeSorting={handleChangeSorting}
                isLoading={isLoadingWinners}
              />
            </CardBody>
          </Card>
        </Col>
      </div>
      <ModalConfirm
        textButtonConfirm={
          typeRandom === "RANDOMLY"
            ? t("Button Randomly selected")
            : t("Button Selected")
        }
        textButtonClose={t("Close")}
        classButtonConfirm={`${
          typeRandom === "RANDOMLY" ? "btn-success" : "btn-primary"
        }`}
        classIconButtonConfirm={"ri-login-circle-line"}
        header={t("Confirm Action")}
        title={t("Update the winner list")}
        content={t("The winner list will be updated. Do you want to update?")}
        isShowIcon={false}
        isOpen={isOpenConfirmSelect}
        onClose={onCloseConfirmSelectClick}
        onConfirm={handleActionSelect}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default ApplicantList;
