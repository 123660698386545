import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface DropdownOptionProps {
  name?: string;
  initialValue?: Option | undefined | null;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  classNamePrefix?: string;
  isHasOptionAll?: boolean;
  optionAll?: undefined | Option;
  isMulti?: boolean;
  isClearable?: boolean
}

const DropdownOption = ({
  name = "",
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
  className = "",
  classNamePrefix = "",
  isHasOptionAll = false,
  optionAll,
  isMulti = false,
  isClearable = false
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const resultOptions =
      dataList?.map((item) => ({
        label: item?.label,
        value: String(item?.value || ""),
      })) || ([] as Option[]);
    const result: any = isHasOptionAll
      ? [optionAll].concat(resultOptions)
      : resultOptions;
    setOptions((_prev: any) => result);

    // if (initialValue && initialValue?.value) {
    //   const val = result?.filter(
    //     (e: any) => e.value === initialValue?.value
    //   )[0];
    //   setOnChangeOption(val);
    // } else {
    //   setOnChangeOption(isHasOptionAll ? result[0] : null);
    // }
    //eslint-disable-next-line
  }, [JSON.stringify(dataList), JSON.stringify(initialValue)]);

  return (
    <React.Fragment>
      <Select
        isMulti={isMulti}
        options={options}
        value={initialValue}
        name={name}
        isDisabled={disabled}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ""}</div>}
        loadingMessage={() => <div>{t("Loading")}...</div>}
        noOptionsMessage={() => <div>{t("No Options")}</div>}
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={isClearable}
      />
    </React.Fragment>
  );
};

export default DropdownOption;
