import { useState } from 'react'

function ImageLoad({ image }: any) {

    const [loaded, setLoaded] = useState(false);

    const handleLoad = () => {
        setLoaded(true);
    };

    return (
        <div>
            <img src={image} className="flex-shrink-0 rounded" style={{ width: '36px', height: '36px', objectFit: 'cover', backgroundColor: '#f8f8f8', display: loaded ? 'block' : 'none' }}

                alt="notification" onLoad={handleLoad}
            />
        </div>
    )
}

export default ImageLoad
