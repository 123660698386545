import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getHashtagsByNormal } from "../../store/thunks";
interface Option {
  label: string;
  value: string;
  isByEnter?: boolean;
}
interface Filter {
  page?: number;
  limit?: number;
  name?: string;
}

interface SearchFilterTagProps {
  name?: string;
  initialValue?: Option[] | Option | undefined | null;
  disabled?: boolean;
  limitShow?: number;
  onChangeSelect?: (params: any) => void;
  isGetListOptionDefault?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  placeholder?: string;
}

const COLORS_STATUS_CAMPAIGN: any = {
  pending: "primary",
  running: "success",
  finished: "danger",
  waiting: "primary",
  error: "warning",
};

const SearchFilterTag = ({
  name = "",
  initialValue,
  isGetListOptionDefault = true,
  disabled = false,
  limitShow = 10,
  onChangeSelect,
  isMulti = false,
  isClearable = false,
  placeholder = "",
}: SearchFilterTagProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[] | undefined>([]);

  const setOnChangeOption = (item: any, option: any = null) => {
    onChangeSelect && onChangeSelect(item);
  };
  const FILTER_BY = { page: 1, limit: limitShow, name: "" };

  const promiseOptions = (inputValue: string) => {
    return new Promise<Option[]>((resolve) => {
      getHashtagsByNormal({ ...FILTER_BY, name: inputValue }).then(
        (response: any) => {
          const result =
            response?.data?.list?.map((item: any) => ({
              label: item?.name,
              value: item?.id,
            })) || [];
          setOptions((_prev) => result);
          resolve(result);
        }
      );
    });
  };

  const promiseCallHashtags = async (params: Filter = {}) => {
    try {
      const response: any = await getHashtagsByNormal({
        ...FILTER_BY,
        ...params,
      });
      const result =
        response?.data?.list?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        })) || [];
      setOptions((_prev) => result);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (isGetListOptionDefault) {
      promiseCallHashtags();
    }
    //eslint-disable-next-line
  }, [isGetListOptionDefault]);

  return (
    <React.Fragment>
      <AsyncSelect
        cacheOptions
        defaultOptions={options}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={disabled}
        closeMenuOnSelect={!isMulti}
        name={name}
        value={initialValue}
        loadOptions={promiseOptions}
        onChange={(e: any, op: any) => setOnChangeOption(e, op)}
        placeholder={<div>{placeholder || t("Search or Select Target Tag")}...</div>}
        loadingMessage={() => <div>{t("Loading")}...</div>}
        noOptionsMessage={() => <div>{t("No Options")}</div>}
        formatOptionLabel={(option: any) => (
          <div className="d-flex">
            {option?.label}
          </div>
        )}
        className="search-filter-campaign"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default SearchFilterTag;
