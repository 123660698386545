import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IWinner } from "./types/_winner";
const api = new APIClient();

const path = "/winners";
const winnerApi = {
  winners(
    params: any
  ): Promise<AxiosResponse<ResponseData<IWinner[]> & PaginationResponse, any>> {
    const url = `${path}/type`;
    return api.get(url, params);
  },
  postRandomSelectWinner(
    campaign_id: string,
    dataForm: any = {}
  ): Promise<AxiosResponse<ResponseData<IWinner>, any>> {
    const url = `${path}/random-select/${campaign_id}`;
    return api.post(url, dataForm);
  },
  postSelectWinner(
    dataForm: any = {}
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },

  deleteWinner(
    dataForm: any = {}
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}`;

    return api.delete(url, { data: dataForm });
  },

  applicants(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `applicants`;
    return api.get(url, params);
  },
};
export default winnerApi;
