import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IUserStatistic } from "api/types/_statistic";
import { getUserStatistics } from "./thunk";

export interface IState {
  users: (ResponseData<IUserStatistic[]> & PaginationResponse) | null;
  isUserLoading: boolean;
  isUserSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  users: null,
  isUserLoading: false,
  isUserSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Users
    builder.addCase(getUserStatistics.pending, (state: IState, action: any) => {
      state.isUserLoading = true;
    });
    builder.addCase(getUserStatistics.fulfilled, (state: IState, action: any) => {
      state.users = action.payload.data;
      state.isUserSuccess = true;
      state.isUserLoading = false;
    });
    builder.addCase(getUserStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUserSuccess = false;
      state.isUserLoading = false;
    });
  },
});

export default StatisticSlice.reducer;
