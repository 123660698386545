import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import campaignApi from "api/campaignApi";
import { formatQueryParams } from "helpers/format";

export const getCampaigns = createAsyncThunk(
  "campaigns",
  async (params: any = {}) => {
    try {
      const response = await campaignApi.campaigns(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCampaignsByNormal = async (params: any = {}) => {
  try {
    const response = await campaignApi.campaigns(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getDetailCampaign = async (
  id: string | number,
  params: any = {}
) => {
  try {
    const response = await campaignApi.campaign(id, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const postCampaign = async (data: any = {}) => {
  try {
    const response = await campaignApi.postCampaign(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putCampaign = async (id: string | number, data: any = {}) => {
  try {
    const response = await campaignApi.putCampaign(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const putCampaignStatus = async (data: any = {}) => {
  try {
    const response = await campaignApi.putCampaignStatus(data);
    return response;
  } catch (error) {
    return error;
  }
};
export const postCampaignMultiMedia = async (data: any = {}) => {
  try {
    const response = await campaignApi.postCampaignMultiMedia(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putCampaignMultiMedia = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await campaignApi.putCampaignMultiMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCampaign = async (id: string | number, data: any = {}) => {
  try {
    const response = await campaignApi.deleteCampaign(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const exportFileCampaign = async (params: any = {}) => {
  try {
    const response = await campaignApi.exportFileCampaign(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const createCampaign = async (params: any = {}) => {
  try {
    const response = await campaignApi.createCampaign(params);
    return response;
  } catch (error) {
    return error;
  }
};
