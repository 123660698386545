import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { externalLogin, getInfoExternalLogin } from "../../store/thunks";
import withRouter from "../../components/Common/withRouter";
import ModalConfirm from "components/Common/ModalConfirm";
import "./SSORedirectStyled.scss";

function SSORedirect(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const params = new URLSearchParams(window.location.search);
  const ssoToken = params.get("sso_token");
  const ssoTokenNewshub = params.get("sso_token_newshub");
  const [isShow, setIsShow] = useState(false);
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const redirectLogin = () => {
    props?.router?.navigate("login");
  };

  const redirectDashboard = () => {
    props?.router?.navigate("dashboard");
  };

  const handleGetInfoExternalLogin = async (token: any) => {
    const getInfoTokenNewshub = await getInfoExternalLogin(token);
    return getInfoTokenNewshub();
  };

  const handleSynchronizeAccount = () => {
    if (ssoTokenNewshub) {
      const token = ssoTokenNewshub;
      dispatch(externalLogin(token, props.router.navigate, "/dashboard"));
    }
  };

  const showModal = () => {
    setIsShow(true);
  };

  const init = async () => {
    if (ssoToken) {
      const token = ssoToken;
      dispatch(externalLogin(token, props.router.navigate, "/dashboard"));
    } else if (ssoTokenNewshub) {
      const authUser: any = localStorage.getItem("authUser");
      const infoExternalLogin = await handleGetInfoExternalLogin(
        ssoTokenNewshub
      );

      if (
        localStorage.getItem("authUser") &&
        infoExternalLogin &&
        (infoExternalLogin as any)?.code === 200 &&
        (infoExternalLogin as any)?.data
      ) {
        const obj = JSON.parse(authUser);
        const email = obj?.user?.email;
        const emailNewsHub = (infoExternalLogin as any)?.data?.user?.email;

        if (emailNewsHub === email) {
          props?.router?.navigate("/dashboard");
        } else {
          showModal();
        }
      } else {
        handleSynchronizeAccount();
      }
    } else {
      redirectLogin();
    }
  };

  useEffect(() => {
    init();
    //eslint-disable-next-line
  }, [ssoToken, ssoTokenNewshub]);

  return (
    <>
      <div className="sso-redirect">
        <span>{t("Navigation is currently being carried out")}</span>
        <svg
          width="50"
          height="16"
          viewBox="0 0 50 16"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginTop: "10px" }}
        >
          <circle cx="6" cy="8" r="2" fill="#333">
            <animate
              attributeName="opacity"
              values="0;1;0"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="16" cy="8" r="2" fill="#333">
            <animate
              attributeName="opacity"
              values="0;1;0"
              begin="0.33s"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="26" cy="8" r="2" fill="#333">
            <animate
              attributeName="opacity"
              values="0;1;0"
              begin="0.66s"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </div>
      <ModalConfirm
        textButtonConfirm={t("Button Yes")}
        textButtonClose={t("Keep Account")}
        classButtonConfirm="btn-success"
        classIconButtonConfirm={"ri-login-circle-line"}
        header={t("Switch Account")}
        title={t("Switch to using an account from NewsHub")}
        content={t(
          "Your current account is not synchronized with NewsHub. Do you want to update your account?"
        )}
        isShowIcon={false}
        isOpen={isShow}
        onClose={redirectDashboard}
        onConfirm={handleSynchronizeAccount}
        isLoading={isLoading}
      />
    </>
  );
}

SSORedirect.propTypes = {};

export default withRouter(SSORedirect);
