import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import deviceApi from "api/deviceApi";
import { formatQueryParams } from "helpers/format";

export const getDevices = createAsyncThunk(
  "Devices",
  async (params: any = {}) => {
    try {
      const response: any = await deviceApi.devices(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getDevice = async (id: string | number, params: any = {}) => {
  try {
    const response = await deviceApi.getDevice(id, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const postDevice = async (data: any = {}) => {
  try {
    const response = await deviceApi.postDevice(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putDevice = async (id: string | number, data: any = {}) => {
  try {
    const response = await deviceApi.putDevice(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteDevice = async (id: string | number, data: any = {}) => {
  try {
    const response = await deviceApi.deleteDevice(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getSubscriptionsALlOS = async () => {
  try {
    const response = await deviceApi.getSubscriptionsALlOS();
    return response;
  } catch (error) {
    return error;
  }
};

export const getSubscriptionsAllDevice = async () => {
  try {
    const response = await deviceApi.getSubscriptionsAllDevice();
    return response;
  } catch (error) {
    return error;
  }
};

export const getUnSubscriptionLogsByNormal = async (params: any = {}) => {
  try {
    const response = await deviceApi.getUnSubscriptionLogsByNormal(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getHashTags = async () => {
  try {
    const response = await deviceApi.getAllHashtag();
    return response;
  } catch (error) {
    return error;
  }
};

export const getHashtagsByNormal = async (params:any ={}) => {
  try {
    const response = await deviceApi.getHashtagsByNormal(params);
    return response;
  } catch (error) {
    return error;
  }
};

