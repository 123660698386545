import { useRole } from "components/Hooks/UserHooks";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";

const Dashboard = () => {
  document.title = "Dashboard | MessageHub";
  const { userPermissions } = useRole();

  useEffect(() => {
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  {isHavePermissionRole(
                    ROLES_FOR_APP.DASHBOARD_VIEW,
                    userPermissions
                  ) && <Widget />}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
