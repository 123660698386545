import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

interface DatePickerProps {
  name?: string;
  startDate?: any;
  endDate?: any;
  onChangePicker?: (params: any) => void;
  placeholder?: string;
  disabled?: boolean;
}

registerLocale("en", en);
registerLocale("ko", ko);

const DatePickerCustom = ({
  name = "",
  startDate = null,
  endDate = null,
  onChangePicker,
  placeholder,
  disabled = false,
}: DatePickerProps) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleChangePicker = (item: any) => {
    onChangePicker && onChangePicker(item);
  };

  const presets = [
    { label: t("Today"), value: [new Date(), new Date()] },
    {
      label: t("Yesterday"),
      value: [
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
    },
    {
      label: t("Last 3 days"),
      value: [
        new Date(new Date().setDate(new Date().getDate() - 3)),
        new Date(),
      ],
    },
    {
      label: t("Last 7 days"),
      value: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ],
    },
    {
      label: t("Last 14 days"),
      value: [
        new Date(new Date().setDate(new Date().getDate() - 14)),
        new Date(),
      ],
    },
    {
      label: t("Last 30 days"),
      value: [
        new Date(new Date().setDate(new Date().getDate() - 30)),
        new Date(),
      ],
    },
    {
      label: t("Last 90 days"),
      value: [
        new Date(new Date().setDate(new Date().getDate() - 90)),
        new Date(),
      ],
    },
  ];

  const handlePresetClick = (preset: any) => {
    onChangePicker && onChangePicker(preset.value);
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <DatePicker
        name={name}
        selectsRange={true}
        className="form-control search "
        popperClassName="form-control-popper-date-picker-range"
        placeholderText={placeholder || `${t("Date News")}...`}
        startDate={startDate || null}
        endDate={endDate || null}
        dateFormat="yyyy-MM-dd"
        isClearable={true}
        disabled={disabled}
        useShortMonthInDropdown={true}
        locale={i18n?.language === "ko" ? "ko" : "en"}
        // timeIntervals={15}
        onChange={handleChangePicker}
        onInputClick={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <div className="picker-presets">
          <ul>
            {presets.map((preset) => (
              <li
                key={preset.label}
                onClick={() => handlePresetClick(preset)}
                className={
                  startDate &&
                  endDate &&
                  startDate?.toDateString() ===
                    preset.value[0].toDateString() &&
                  endDate?.toDateString() === preset.value[1].toDateString()
                    ? "preset-active"
                    : ""
                }
              >
                {preset.label}
              </li>
            ))}
          </ul>
        </div>
      </DatePicker>
    </React.Fragment>
  );
};

export default DatePickerCustom;
