import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = "/statistic";
const statisticsApi = {
  userStatistics(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}/user`;
    return api.get(url, params);
  },
};
export default statisticsApi;
