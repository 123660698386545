import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IDevice, IUnSubscriptionLog } from "./types/_device";
import { PaginationResponse, ResponseData } from "./types/_public";
import { formatQueryParams } from "helpers/format";
const api = new APIClient();

const path = "/subscriptions";
const DeviceApi = {
  devices(
    params: any
  ): Promise<AxiosResponse<ResponseData<IDevice[]> & PaginationResponse, any>> {
    const url = `${path}/list`;
    return api.get(url, params);
  },
  getDevice(
    id: string | number,
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IDevice>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params);
  },
  postDevice(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IDevice>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },
  putDevice(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IDevice>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteDevice(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IDevice>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm);
  },
  getSubscriptionsALlOS(): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/os/all`;
    return api.get(url);
  },

  getSubscriptionsAllDevice(): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/device/all`;
    return api.get(url);
  },

  getUnSubscriptionLogsByNormal(
    params: any
  ): Promise<AxiosResponse<ResponseData<IUnSubscriptionLog[]>, any>> {
    const url = `/unsubscriptions`;
    return api.get(url, formatQueryParams(params));
  },
  getAllHashtag(): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `hashtags`;
    return api.get(url);
  },
  getHashtagsByNormal(params: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `hashtags`;
    return api.get(url, params);
  },
};
export default DeviceApi;
