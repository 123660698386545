import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import UploadApi from "api/uploadApi";

export const uploadFiles = createAsyncThunk(
  "Upload",
  async (params: any = {}) => {
    try {
      const response: any = await UploadApi.uploadFile(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);
