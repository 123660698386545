import { createAsyncThunk } from "@reduxjs/toolkit";
import statisticsApi from "api/statisticsApi";
import { formatQueryParams } from "helpers/format";

export const getUserStatistics = createAsyncThunk(
  "UsersStatistics",
  async (params: any = {}) => {
    try {
      const response: any = await statisticsApi.userStatistics(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);
