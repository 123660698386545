import { WithContext as ReactTags } from "react-tag-input";

const ID_SELECTED_DEFAULT = "";

export interface Tag {
  id: string;
  text: string;
}
interface HashtagProps {
  initialValue?: any[];
  placeholder?: string;
  onChangeTag?: (params: Tag[]) => void;
  idSelected?: string;
  type?: string;
  onClickTag?: (params: any) => void;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Hashtag = ({
  initialValue = [],
  placeholder = "",
  onChangeTag,
  idSelected = ID_SELECTED_DEFAULT,
  type = "",
  onClickTag,
}: HashtagProps) => {
  const handleDelete = (i: number) => {
    onChangeTag &&
      onChangeTag((initialValue || []).filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: any) => {
    const newTag = {
      id: tag.id.replace(/\s/g, ""),
      text: tag.text.replace(/\s/g, ""),
    };
    onChangeTag && onChangeTag([...initialValue, newTag]);
  };

  const handleDeleteValue = (tag: any) => {
    onChangeTag && onChangeTag([]);
  };

  const handleOnClickTag = (i: any) => {
    if (type !== "target") return;
    const targetTag = initialValue?.find(
      (tag: any, index: number) => index === i
    );
    onClickTag && onClickTag(targetTag);
  };

  return (
    <div className="position-relative hashtags-custom">
      <ReactTags
        tags={initialValue}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="top"
        autocomplete={true}
        allowDeleteFromEmptyInput={true}
        allowDragDrop={false}
        placeholder={placeholder}
        inputProps={{
          disabled: (type === "target") as any,
        }}
        classNames={{
          // tags: "hashtags-custom",
          tagInputField: "form-control",
          selected: `ReactTags__selected hashtags_selected ${idSelected}`,
          remove: `${type === "target" ? "d-none" : "ReactTags__remove"}`,
        } as any}
        handleTagClick={(i) => handleOnClickTag(i)}
      />
      {initialValue?.length > 0 && type !== "target" && (
        <span
          className="mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer"
          onClick={handleDeleteValue}
          style={{
            right: "8px",
            top: 5,
            fontSize: "20px",
            color: "var(--vz-header-item-sub-color)",
          }}
        ></span>
      )}
    </div>
  );
};

export default Hashtag;
