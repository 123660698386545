import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import domainApi from "api/domainApi";
import { formatQueryParams } from "helpers/format";

export const getDomains = createAsyncThunk(
  "Domains",
  async (params: any = {}) => {
    try {
      const response: any = await domainApi.domains(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllDomains = async (params: any = {}) => {
  try {
    const response = await domainApi.allDomains(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getDomain = async (id: string | number, params: any = {}) => {
  try {
    const response = await domainApi.getDomain(id, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const postDomain = async (data: any = {}) => {
  try {
    const response = await domainApi.postDomain(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putDomain = async (id: string | number, data: any = {}) => {
  try {
    const response = await domainApi.putDomain(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteDomain = async (id: string | number, data: any = {}) => {
  try {
    const response = await domainApi.deleteDomain(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getScriptTrackingMedia = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await domainApi.renderScriptTrackingNews(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getHashtagsByMedia = async (formData: any) => {
  try {
    const response = await domainApi.getHashtagsMedia(formData);
    return response;
  } catch (error) {
    return error;
  }
};
