import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";

import { IDomain } from "api/types/_domain";
import { INotificationLog } from "api/types/_notification";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import SearchFilterCampaign from "components/Common/SearchFilterCampaign";
import SearchFilterDomain from "components/Common/SearchFilterDomain";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import {
  BOT_OPTIONS,
  BROWSER_OPTIONS,
  DEVICE_OPTIONS,
  OS_OPTIONS,
  PLATFORM_LOG_OPTIONS,
  TYPE_NEWS_OPTIONS,
} from "helpers/constans";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import CountUp from "react-countup";
import { getAllDomains } from "store/domain/thunk";
import {
  getNotificationLogsByNormal,
  getNotificationsListAllDevice,
} from "store/notification/thunk";
import ImageLoad from "./ImageLoad";
import { getHashTags } from "store/thunks";
import LabelWrapper from "components/Common/LabelWrapper";
import CopyWrapper from "components/Common/CopyWrapper";
import { useDurationResponses } from "components/Hooks/DurationResponsesHooks";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const COLORS_STATUS_CAMPAIGN: any = {
  pending: "primary",
  success: "success",
  error: "danger",
};

const COLORS_STATUS_IS_CLICKED: any = {
  1: "success",
  0: "danger",
};

const ClickLogList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const { setDurationResponses } = useDurationResponses();

  const PLATFORM_LOG_OPTIONS_LANG =
    PLATFORM_LOG_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const DEVICE_OPTIONS_LANG =
    DEVICE_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const BOT_OPTIONS_LANG =
    BOT_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const BROWSER_OPTIONS_LANG =
    BROWSER_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const OS_OPTIONS_LANG =
    OS_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const TYPE_NEWS_OPTIONS_LANG =
    TYPE_NEWS_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, 'created_at'),
    order_by: withDefault(StringParam, `DESC`),
    media_id: withDefault(StringParam, ""),
    title: withDefault(StringParam, ""),
    reference_link: withDefault(StringParam, ""),
    ip: withDefault(StringParam, ""),
    og_url: withDefault(StringParam, ""),
    keyword: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    platform: withDefault(StringParam, ""),
    device_name: withDefault(StringParam, ""),
    browser: withDefault(StringParam, ""),
    os: withDefault(StringParam, ""),
    bot: withDefault(StringParam, ""),
    is_newshub: withDefault(StringParam, ""),
    id: withDefault(StringParam, ""),
    campaign_name: withDefault(StringParam, ""),
    is_clicked: withDefault(StringParam, "1"),
    subscription_uuid: withDefault(StringParam, ""),
    campaign_id: withDefault(StringParam, ""),
    hostname: withDefault(StringParam, ""),
    status: withDefault(StringParam, ""),
    hashtags: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;

  const [referenceLinkSearch, setReferenceLinkSearch] = useState<string>(
    query?.reference_link || ""
  );
  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [urlSearch, setUrlSearch] = useState<string>(query?.og_url || "");

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [listDomain, setListDomain] = useState<IDomain[]>([]);

  const [listHashtag, setListHashtag] = useState<any>([]);

  const [domainSearch, setDomainSearch] = useState<Option | null>({
    label:
      listDomain?.filter(
        (item) => String(item?.website || "") === String(query?.domain || "")
      )[0]?.name || t("All Media"),
    value: `${listDomain?.filter(
      (item) => String(item?.website || "") === String(query?.domain || "")
    )[0]?.id || ""
      }`,
  });

  const [platformSearch, setPlatformSearch] = useState<Option | null>(
    PLATFORM_LOG_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.platform)
    )[0]
  );

  const [deviceSearch, setDeviceSearch] = useState<Option | null>(
    DEVICE_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.device)
    )[0] || ({ label: t("All Device"), value: "" })
  );

  const [botSearch, setBotSearch] = useState<Option | null>(
    BOT_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.bot)
    )[0]
  );

  const [browserSearch, setBrowserSearch] = useState<Option | null>(
    BROWSER_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.browser)
    )[0] || ({ label: t("All Browser"), value: "" })
  );

  const [osSearch, setOsSearch] = useState<Option | null>(
    OS_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.os)
    )[0] || ({ label: t("All OS"), value: "" })
  );

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(
    TYPE_NEWS_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.is_newshub)
    )[0]
  );

  const [previewDetail, setPreviewDetail] = useState<any | null>(null);
  // Inside your component

  const [notificationLogs, setNotificationLogs] = useState<{
    list: INotificationLog[];
    total: number;
  }>({ list: [], total: 0 });
  const [isNotificationLogLoading, setIsNotificationLogLoading] = useState<boolean>(false);

  const [listStatus, setListStatus] = useState<any>([]);

  const [status, setStatus] = useState<any>({
    label: t("All Status"),
    value: "",
  });

  const [listOS, setListOS] = useState<any>(OS_OPTIONS_LANG);

  const [listDevice, setListDevice] = useState<any>([]);

  const [campaignNameSearch, setCampaignNameSearch] = useState<string>(
    query?.campaign_name || ""
  );
  const [notificationLogNoSearch, setNotificationLogNoSearch] =
    useState<string>(query?.id || "");

  const [userUUIDSearch, setUserUUIDSearch] = useState<string>(
    query?.userUUID || ""
  );

  const [campaignSearch, setCampaignSearch] = useState<Option | null>(null);

  const [listHostName, setListHostName] = useState<any>([]);

  const [hostNameSearch, setHostNameSearch] = useState<Option | null>();

  const [hashtagSearch, setHashtagSearch] = useState<any | null>([]);

  // eslint-disable-next-line
  const [listBrowser, setListBrowser] = useState<any>(BROWSER_OPTIONS_LANG);

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1,
    });
  };

  useEffect(() => {
    const handleQueryData = async () => {
      setIsNotificationLogLoading((prev) => true);
      const res: any = await getNotificationLogsByNormal(query);
      setNotificationLogs((prev: any) => ({
        list: (prev?.list || []).concat(res?.data?.list || []),
        total: res?.data?.pagination?.total || 0,
      }));
      setIsNotificationLogLoading((prev) => false);
      setDurationResponses([{
        name: 'Click Log',
        time: res?.data?.pagination?.duration || 0
      }]);
    };

    handleQueryData();
    // eslint-disable-next-line
  }, [JSON.stringify(query)]);

  useEffect(() => {
    const handleUpdateDomain = () => {
      if (domainSearch && domainSearch.value) {
        const getDomain = listDomain.find((item: any) => {
          return item.id === domainSearch.value;
        });
        if (getDomain && getDomain.website) {
          const domainArr = getDomain.website.split(",");
          const listDomainOptions = domainArr.map((item) => ({
            label: item,
            value: item,
          }));
          setListHostName(listDomainOptions);
          // domainArr.map(())
        }
      } else {
        setListHostName([]);
      }
    };

    handleUpdateDomain();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainSearch]);

  const searchData = () => {
    const queryNew = {
      ...query,
      title: titleSearch || "",
      reference_link: referenceLinkSearch || "",
      ip: ipSearch || "",
      og_url: urlSearch || "",
      keyword: keywordSearch || "",
      media_id: domainSearch?.value
        ? listDomain?.filter(
          (item: any) => Number(item?.id) === Number(domainSearch?.value)
        )[0]?.website || domainSearch?.value
        : "",
      device_name: deviceSearch?.value || "",
      bot: botSearch?.value ?? "",
      browser: browserSearch?.value ?? "",
      platform: osSearch?.value ?? "",
      is_newshub: typeNewsSearch?.value ?? "",
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      // sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
      id: notificationLogNoSearch || "",
      campaign_name: campaignNameSearch || "",
      is_clicked: '1',
      status: status?.value ? status?.value : "",
      subscription_uuid: userUUIDSearch || "",
      campaign_id: campaignSearch?.value || "",
      hostname: hostNameSearch?.value ?? "",
      hashtags: hashtagSearch?.map((item: any) => item?.label)?.join(",") || "",
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNotificationLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      title: "",
      reference_link: "",
      ip: "",
      og_url: "",
      keyword: "",
      media_id: "",
      // sort_by: TYPE_SELECT_DEFAULT,
      start_date: moment(new Date()).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      platform: "",
      device_name: "",
      bot: "",
      browser: "",
      os: "",
      is_newshub: "",
      page: 1,
      time_request: +new Date(),
      id: "",
      campaign_name: "",
      is_clicked: '1',
      subscription_uuid: "",
      campaign_id: undefined,
      hostname: "",
      status: "",
      hashtags: "",
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setNotificationLogs((prev: any) => ({
        list: [],
        total: 0,
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0,
      }));
    }
    setQuery(queryNew, "push");
    setReferenceLinkSearch((_prev) => "");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setTitleSearch((_prev) => "");
    setDomainSearch({ label: t("All Media"), value: "" });
    setDateSearch([
      moment(queryNew?.start_date || "", "Y-MM-DD").toDate(),
      moment(queryNew?.end_date || "", "Y-MM-DD").toDate(),
    ]);
    setPlatformSearch(null);
    setDeviceSearch({ label: t("All Device"), value: "" });
    setBotSearch(null);
    setBrowserSearch(({ label: t("All Browser"), value: "" }));
    setOsSearch(({ label: t("All OS"), value: "" }));
    setTypeNewsSearch(null);
    setNotificationLogNoSearch("");
    setCampaignNameSearch("");
    setStatus({
      label: t("All Status"),
      value: "",
    });
    setUserUUIDSearch((_prev) => "");
    setCampaignSearch(null);
    setHostNameSearch({ label: t("All Domain"), value: "" });
    setHashtagSearch([]);
  };

  function onClosePreviewDetailClick() {
    setPreviewDetail((_prev: any) => null);
  }

  const handlePreviewDetail = async (item: any | null = null) => {
    try {
      setPreviewDetail((_prev: any) => item);
    } catch (error: any) {
      return error;
    }
  };

  const showListDomain = (domain: any, hostname: any) => {
    if (domain) {
      const valueConvertArr = domain.split(",");
      const show: any[] = [];
      // eslint-disable-next-line
      valueConvertArr.map((value: any) => {
        show.push(
          <div
            className="d-flex flex-row align-items-center"
            style={{ gap: "8px" }}
          >
            <Link
              className="text-normal"
              to={
                String(value).includes("https://") ? value : `https://${value}`
              }
              target="_blank"
            >
              {value}
            </Link>
            {hostname === value && (
              <div className="remove-list-style">
                <TooltipCustom
                  title={t("User operated with this domain")}
                  className="d-inline-block vertical-align-middle ms-1 remove-list-style"
                  style={{ transform: "translateY(2px)" }}
                >
                  <div id={`check-domain`}>
                    <FeatherIcon
                      class="text-success"
                      icon="check-circle"
                      size={16}
                    />
                  </div>
                </TooltipCustom>
              </div>
            )}
          </div>
        );
      });

      return (
        <div className="d-flex" style={{ flexDirection: "column", gap: "2px" }}>
          {show}
        </div>
      );
    } else {
      return null;
    }
  };

  const showColumnEvent = (cell: any) => {
    let color = COLORS_STATUS_IS_CLICKED[cell?.value];

    if (query && query.is_send && Number(query.is_send) === 1) {
      let color = COLORS_STATUS_IS_CLICKED[0];

      return <span className={`badge bg-${color}`}>{t("Send")}</span>;
    }

    if (query && query.is_clicked && Number(query.is_clicked) === 1) {
      let color = COLORS_STATUS_IS_CLICKED[1];

      return <span className={`badge bg-${color}`}>{t("Click")}</span>;
    }

    return cell?.value === 1 ? (
      <span className={`badge bg-${color}`}>{t("Click")}</span>
    ) : (
      <span className={`badge bg-${color}`}>{t("Send")}</span>
    );
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Event"),
        accessor: "is_clicked",
        filterable: true,
        sortable: false,
        thWidth: 80,
        Cell: (cell: any) => {
          return (
            <div style={{ width: "80px" }}>
              {showColumnEvent(cell)}
              {/* {cell?.value === 1 ? <span className={`badge bg-${color}`}>{t('Click')}</span> : <span className={`badge bg-${color}`}>{t('Send')}</span>} */}
            </div>
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "status",
        filterable: true,
        sortable: false,
        thWidth: 80,
        Cell: (cell: any) => {
          const color = COLORS_STATUS_CAMPAIGN[cell?.value];
          return (
            <div style={{ width: "80px" }}>
              <span className={`badge bg-${color}`}>{t(cell?.value)}</span>
            </div>
          );
        },
      },
      {
        Header: t("UUID"),
        accessor: "subscription.uuid",
        filterable: true,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const { uuid } = item;
          return (
            <>
              <CopyWrapper contentCopy={uuid}>{uuid}</CopyWrapper>
            </>
          );
        },
      },
      {
        Header: t("Notification"),
        accessor: "title",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        thWidth: 320,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const { title, image_url: image, message, url } = item;
          return (
            <Link
              className="text-normal text-center"
              to={
                String(url || "").includes("https://") ? url : `https://${url}`
              }
              target="_blank"
            >
              <div className="d-flex div-warper-notification">
                <div
                  className="title-warper-notification text-start"
                  style={{ width: "100%" }}
                >
                  {title}
                </div>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="content-warper-notification text-muted text-start"
                    style={{
                      width: `calc(100% - ${!!image ? "40px" : "0px"})`,
                    }}
                  >
                    {message}
                  </div>
                  {!!image && <ImageLoad image={image} />}
                </div>

                <div className="text-start">{url}</div>
              </div>
            </Link>
          );
        },
      },
      {
        Header: t("Campaign"),
        accessor: "campaign.name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '100px' }}>{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Media"),
        accessor: "media",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const { media = {}, hostname } = item;
          return (
            <>
              <div
                className="d-flex flex-column"
                style={{ minWidth: "max-content" }}
              >
                <div>{media?.name}</div>
                <div className="cursor-pointer">
                  {showListDomain(media?.domain, hostname)}
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Tag"),
        accessor: "hashtags",
        filterable: false,
        sortable: false,
        minWidth: 200,
        cellClass: "mb-auto",
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const hashtags = value?.hashtags || [];
          console.log(value);

          return (
            <div style={{ minWidth: "100px" }}>
              {hashtags?.map((k: any, index: number) => (
                <span className="tag-style" key={index}>
                  {k}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: t("WEB_ip"),
        accessor: "ip",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          return (
            <>
              <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
            </>
          );
        },
      },
      {
        Header: t("WEB_device"),
        accessor: "subscription.device_name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const { device_name } = item;

          return (
            <>
              <div>{device_name ? t(device_name) : t(cell?.value)}</div>
            </>
          );
        },
      },
      {
        Header: t("WEB_platform"),
        accessor: "subscription.platform",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const { platform } = item;
          return (
            <>
              <span>
                {platform
                  ? PLATFORM_LOG_OPTIONS_LANG?.filter(
                    (item) =>
                      !!platform && String(item?.value) === String(platform)
                  )[0]?.label || platform
                  : PLATFORM_LOG_OPTIONS_LANG?.filter(
                    (item) =>
                      !!cell?.value &&
                      String(item?.value) === String(cell?.value)
                  )[0]?.label || cell?.value}
              </span>
            </>
          );
        },
      },
      {
        Header: t("Browser"),
        accessor: "subscription.browser",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const { browser } = item;
          return (
            <>
              <div>{browser ? browser : cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 110,
        Cell: (cell: any) => {
          return <div style={{ minWidth: '100px' }}>{cell?.value}</div>;
        },
      },
      {
        Header: t("Button Action"),
        thClass: "justify-content-center",
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              <TooltipCustom title={t("Detail")} id={`detail-log-${item?.id}`}>
                <li
                  className=" list-inline-item me-0"
                  id={`detail-log-${item?.id}`}
                >
                  <Link
                    className="btn btn-sm btn-soft-secondary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePreviewDetail(item);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language, query]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line
  }, []);

  const handleSetListOS = (resListAllOS: any) => {
    if (resListAllOS && resListAllOS?.data && resListAllOS?.data?.list) {
      const listALLOS = resListAllOS?.data?.list.map((item: string) => ({
        label: item,
        value: item,
      }));
      return listALLOS;
    } else {
      return [];
    }
  };

  const handleSetListDevice = (resListDevice: any) => {
    if (resListDevice && resListDevice?.data && resListDevice?.data?.list) {
      const listAllDevice = resListDevice?.data?.list.map((item: string) => ({
        label: t(item),
        value: item,
      }));
      return listAllDevice;
    } else {
      return [];
    }
  };

  const handleCallAllOption = async () => {
    try {
      // const [resDomain, resListAllOS, resListAllDevice]: any = await Promise.all([getAllDomains(), getNotificationsListAllOS(), getNotificationsListAllDevice()]);
      const [resDomain, resListAllDevice, resTag]: any = await Promise.all([
        getAllDomains(),
        getNotificationsListAllDevice(),
        getHashTags()
      ]);
      if (resDomain) {
        const res = resDomain?.data?.list?.map((item: any, index: number) => ({
          id: item?.id,
          name: item?.name,
          website: item?.domain,
        }));
        const list = [
          { name: t("All Media"), id: "", website: "" } as any,
        ].concat(res || []);
        setListDomain((_prev) => list);

        setListOS((_prev: any) =>
          handleSetListOS({
            code: 200,
            message: "Success",
            data: {
              list: ["Android", "iOS", "Windows", "Mac", "Etc"],
            },
          })
        );

        setListDevice((_prev: any) => handleSetListDevice(resListAllDevice));

        if (resTag) {
          const newData = resTag?.data?.list?.map((item: any) => ({
            value: item?.id,
            label: item?.name,
          }));
          setListHashtag(newData);
          const arrTagInParams = query?.hashtags?.split(',');
          setHashtagSearch(newData?.filter((item: any) => arrTagInParams?.includes(item?.value)));
        }
      }
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (platformSearch) {
      setPlatformSearch(
        (_prev: any) =>
          PLATFORM_LOG_OPTIONS_LANG?.filter(
            (e: any) => e.value === platformSearch?.value
          )[0]
      );
    }

    if (deviceSearch) {
      setDeviceSearch(
        (_prev: any) =>
          listDevice?.filter(
            (e: any) => e.value === deviceSearch?.value
          )[0] || ({ label: t("All Device"), value: "" })
      );
    }

    if (botSearch) {
      setBotSearch(
        (_prev: any) =>
          BOT_OPTIONS_LANG?.filter((e: any) => e.value === botSearch?.value)[0]
      );
    }

    if (domainSearch) {
      setListDomain((_prev) =>
        (_prev || [])?.map((item) =>
          item?.id ? item : { ...item, name: t("All Media") }
        )
      );
      !domainSearch?.value &&
        setDomainSearch((prev) => ({ label: t("All Media"), value: "" }));
    }

    if (browserSearch) {
      setBrowserSearch(
        (_prev: any) =>
          BROWSER_OPTIONS_LANG?.filter(
            (e: any) => e.value === browserSearch?.value
          )[0] || ({ label: t("All Browser"), value: "" })
      );
    }

    if (osSearch) {
      setOsSearch(
        (_prev: any) =>
          listOS?.filter((e: any) => e.value === osSearch?.value)[0] || ({ label: t("All OS"), value: "" })
      );
    }

    if (typeNewsSearch) {
      setTypeNewsSearch(
        (_prev: any) =>
          TYPE_NEWS_OPTIONS_LANG?.filter(
            (e: any) => e.value === typeNewsSearch?.value
          )[0]
      );
    }

    if (typeNewsSearch) {
      setTypeNewsSearch(
        (_prev: any) =>
          TYPE_NEWS_OPTIONS_LANG?.filter(
            (e: any) => e.value === typeNewsSearch?.value
          )[0]
      );
    }

    const statusL = [
      {
        label: t("Pending"),
        value: "pending",
      },
      {
        label: t("Success"),
        value: "success",
      },
      {
        label: t("Error"),
        value: "error",
      },
    ];
    setListStatus(statusL);
    if (status) {
      setStatus(
        (_prev: any) =>
          statusL?.filter(
            (e: any) => e.value === status?.value
          )[0] || ({ label: t('All Status'), value: "", })
      );
    }
    if (hostNameSearch) {
      setListHostName((_prev: any) =>
        (_prev || [])?.map((item: { id: any }) =>
          item?.id ? item : { ...item, name: t("All Domain") }
        )
      );
      !hostNameSearch?.value &&
        setHostNameSearch((prev) => ({ label: t("All Domain"), value: "" }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Click Log")} - ${t("Statistics")} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const handleOnDisableListDomain = () => {
    if (domainSearch && !domainSearch.value) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Click Log")}
            pageTitle={t("Statistics")}
          />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.NEWS_LOG_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-2">
                        <Card
                          className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
                          style={{ width: "200px" }}
                        >
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                                  {t("Total")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {(isNotificationLogLoading && query?.page === 1) ? (
                                      <Spinner size="sm"></Spinner>
                                    ) : (
                                      <CountUp
                                        start={0}
                                        end={notificationLogs?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <Row className="g-4 align-items-center mb-2 mb-md-0 mt-0">
                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3 date-picker-wrapper-custom">
                              <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                <DatePickerCustom
                                  placeholder={`${t("Start Date")} - ${t(
                                    "End Date"
                                  )}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  onChangePicker={handleChangePicker}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('Status')} isShow={!!status?.value}>
                                <DropdownOption
                                  name="format"
                                  dataList={listStatus || []}
                                  placeholder={`${t("Status")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={status || null}
                                  onChangeSelect={(e: any) => setStatus(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Status"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('Media')} isShow={!!domainSearch?.value}>
                                <SearchFilterDomain
                                  name="domains"
                                  isMulti={false}
                                  dataList={listDomain}
                                  initialValue={domainSearch}
                                  onChangeSelect={(event: any) => {
                                    setDomainSearch((_prev) => event);
                                  }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('Domain')} isShow={!!hostNameSearch?.value}>
                                <DropdownOption
                                  name="format"
                                  dataList={listHostName || []}
                                  placeholder={`${t("Domain")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={hostNameSearch || null}
                                  onChangeSelect={(e: any) => setHostNameSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Domain"), value: "" }}
                                  disabled={handleOnDisableListDomain()}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('Device')} isShow={!!deviceSearch?.value}>
                                <DropdownOption
                                  name="format"
                                  dataList={listDevice || []}
                                  placeholder={`${t("Device")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={deviceSearch || null}
                                  onChangeSelect={(e: any) => setDeviceSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Device"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('OS')} isShow={!!osSearch?.value}>
                                <DropdownOption
                                  name="format"
                                  dataList={listOS || []}
                                  placeholder={`${t("OS")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={osSearch || null}
                                  onChangeSelect={(e: any) => setOsSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All OS"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('Browser')} isShow={!!browserSearch?.value}>
                                <DropdownOption
                                  name="format"
                                  dataList={listBrowser || []}
                                  placeholder={`${t("Browser")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={browserSearch || null}
                                  onChangeSelect={(e: any) => setBrowserSearch(e)}
                                  isHasOptionAll={true}
                                  optionAll={{ label: t("All Browser"), value: "" }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('Campaign')} isShow={!!campaignSearch?.value}>
                                <SearchFilterCampaign
                                  limitShow={20}
                                  initialValue={campaignSearch}
                                  isClearable={true}
                                  onChangeSelect={(event) => {
                                    setCampaignSearch((_prev: any) => event);
                                  }}
                                  placeholder={`${t("Search or Select Campaign")}`}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('UUID')} isShow={!!userUUIDSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("UUID")}...`}
                                  value={userUUIDSearch}
                                  onChange={(e) =>
                                    setUserUUIDSearch(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={6} xl={6} xxl={3} className="mt-3">
                              <LabelWrapper label={t('IP')} isShow={!!ipSearch}>
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t("IP")}...`}
                                  value={ipSearch}
                                  onChange={(e) => setIpSearch(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                              </LabelWrapper>
                            </Col>

                            <Col sm={12} md={12} lg={6} xl={3} className="mt-3">
                              <LabelWrapper label={t('Tag')} isShow={hashtagSearch?.length > 0}>
                                <DropdownOption
                                  name="format"
                                  dataList={listHashtag || []}
                                  placeholder={`${t("Tag")}...`}
                                  className="search-filter-category-type"
                                  classNamePrefix="name-prefix"
                                  initialValue={hashtagSearch || null}
                                  onChangeSelect={(e: any) => {
                                    setHashtagSearch((prev: any) => e);
                                  }}
                                  isHasOptionAll={false}
                                  isMulti
                                  isClearable={true}
                                />
                              </LabelWrapper>
                            </Col>
                            <Col sm={12} md={12} lg={6} xl={3} className="hstack gap-1 justify-content-start justify-content-xxl-end mt-3"
                            >
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isNotificationLogLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t("Button Search")}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t("Button Reset")}
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0 px-0">
                      <div>
                        <InfiniteScroll
                          dataLength={notificationLogs?.list?.length || 0}
                          next={handleLoadMore}
                          scrollableTarget="scrollableDiv"
                          hasMore={
                            notificationLogs &&
                              notificationLogs?.list?.length <
                              notificationLogs?.total
                              ? true
                              : false
                          }
                          loader={""} // loader={<LoadingListNotify />}
                          scrollThreshold={"50%"}
                        >
                          <div className="mx-3 my-4">
                            <TableContainer
                              className="custom-header-css"
                              divClass="table-responsive table-card"
                              tableClass="align-middle"
                              theadClass="table-light"
                              columns={columns}
                              data={
                                notificationLogs?.list?.length
                                  ? notificationLogs?.list
                                  : []
                              }
                              customPageSize={query.limit}
                              customPageIndex={query.page - 1}
                              totalRecords={notificationLogs?.total}
                              customPageCount={1}
                              handleChangePage={handleChangePage}
                              manualSorting={true}
                              sorting={{
                                sort_by: query.sort_by,
                                order_by: query.order_by,
                              }}
                              handleChangeSorting={handleChangeSorting}
                              isLoading={isNotificationLogLoading}
                              isShowPagination={false}
                              isShowLoadingBottom={query.page > 1}
                            />
                          </div>
                        </InfiniteScroll>
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </Container>
        <Modal
          isOpen={!!previewDetail}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={onClosePreviewDetailClick}
          keyboard={true}
        >
          <ModalHeader toggle={onClosePreviewDetailClick}>
            {t("Detail")}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="tab-content border border-bottom-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_id")}
                                </td>
                                <td>{previewDetail?.id}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>{t("UUID")}</td>
                                <td>{previewDetail?.subscription?.uuid}</td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_title")}
                                </td>
                                <td>{previewDetail?.title}</td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_message")}
                                </td>
                                <td>{previewDetail?.message}</td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_image")}
                                </td>
                                <td>
                                  {previewDetail?.image_url && (
                                    <Link
                                      className="text-normal"
                                      to={previewDetail?.image_url || ""}
                                      target="_blank"
                                    >
                                      <img
                                        src={previewDetail?.image_url}
                                        className="flex-shrink-0 rounded"
                                        style={{
                                          width: "80px",
                                          height: "auto",
                                          maxHeight: "100px",
                                          objectFit: "cover",
                                        }}
                                        alt="notification"
                                      />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_url")}
                                </td>
                                <td>
                                  <Link
                                    className="text-normal"
                                    to={
                                      String(previewDetail?.url || "").includes(
                                        "https://"
                                      )
                                        ? previewDetail?.url || ""
                                        : `https://${previewDetail?.url}`
                                    }
                                    target="_blank"
                                  >
                                    {previewDetail?.url || ""}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_ip")}
                                </td>
                                <td>{previewDetail?.ip}</td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_device")}
                                </td>
                                <td>
                                  {previewDetail?.device_name
                                    ? t(previewDetail?.device_name)
                                    : t(
                                      previewDetail?.subscription?.device_name
                                    )}
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_platform")}
                                </td>
                                <td>
                                  {previewDetail?.platform
                                    ? previewDetail?.platform
                                    : previewDetail?.subscription?.platform}
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Browser")}
                                </td>
                                <td>
                                  {previewDetail?.browser
                                    ? previewDetail?.browser
                                    : previewDetail?.subscription?.browser}
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_userAgent")}
                                </td>
                                <td>
                                  {previewDetail?.user_agent
                                    ? previewDetail?.user_agent
                                    : previewDetail?.subscription?.user_agent}
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>{t("Event")}</td>
                                <td>
                                  {previewDetail?.is_clicked === 1 ? (
                                    <span
                                      className={`badge bg-${COLORS_STATUS_IS_CLICKED[
                                        previewDetail?.is_clicked
                                      ]
                                        }`}
                                    >
                                      {t("Click")}
                                    </span>
                                  ) : (
                                    <span
                                      className={`badge bg-${COLORS_STATUS_IS_CLICKED[
                                        previewDetail?.is_clicked
                                      ]
                                        }`}
                                    >
                                      {t("Send")}
                                    </span>
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Status")}
                                </td>
                                <td>
                                  {
                                    <span
                                      className={`badge bg-${COLORS_STATUS_CAMPAIGN[
                                        previewDetail?.status
                                      ]
                                        }`}
                                    >
                                      {t(previewDetail?.status)}
                                    </span>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Tag")}
                                </td>
                                <td>
                                  {previewDetail?.hashtags?.map((tag: any) => (<span className={`badge bg-primary me-3`}> {tag} </span>))}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("WEB_campaignNotifications")}
                                </td>
                                <td>
                                  {/* <div className="d-flex align-items-center">{previewDetail?.campaign?.id} </div> */}
                                  <div className="d-flex align-items-center">
                                    {previewDetail?.campaign?.name}{" "}
                                  </div>
                                  {previewDetail?.campaign?.icon_url && (
                                    <Link
                                      className="text-normal"
                                      to={
                                        previewDetail?.campaign?.icon_url || ""
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src={previewDetail?.campaign?.icon_url}
                                        className="flex-shrink-0 rounded"
                                        style={{
                                          width: "40px",
                                          height: "auto",
                                          maxHeight: "40px",
                                          objectFit: "cover",
                                        }}
                                        alt="notification"
                                      />
                                    </Link>
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "140px" }}>{t("Media")}</td>
                                <td>
                                  <div className="text-start">
                                    <div className="text-start">
                                      {previewDetail?.media?.name}
                                    </div>
                                    {showListDomain(
                                      previewDetail?.media?.domain || "",
                                      previewDetail?.hostname
                                    )}
                                    {/* <Link className='text-normal' to={String(previewDetail?.media?.domain || '').includes('https://') ? (previewDetail?.media?.domain || '') : `https://${previewDetail?.media?.domain}`} target="_blank">
                                      {previewDetail?.media?.domain || ''}
                                    </Link> */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "140px" }}>
                                  {t("Created at")}
                                </td>
                                <td>{previewDetail?.created_at}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12 mb-4">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-soft-secondary fs-14"
                          type="button"
                          color="light"
                          onClick={onClosePreviewDetailClick}
                        >
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t("Button Close")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ClickLogList;
