//Include Both Helper File with needed methods
import winnerApi from "api/winnerApi";
import { formatQueryParams } from "helpers/format";

export const getWinners = async (params: any = {}) => {
  try {
    const response = await winnerApi.winners(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const getApplicants = async (params: any = {}) => {
  try {
    const response = await winnerApi.applicants(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const postRandomSelectWinner = async (
  campaign_id: string,
  data: any = {}
) => {
  try {
    const response = await winnerApi.postRandomSelectWinner(campaign_id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const postSelectWinner = async (data: any = {}) => {
  try {
    const response = await winnerApi.postSelectWinner(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteWinner = async (data: any = {}) => {
  try {
    const response = await winnerApi.deleteWinner(data);
    return response;
  } catch (error) {
    return error;
  }
};
