import { createSlice } from "@reduxjs/toolkit";
import { INotification } from "api/types/_notification";
import { PaginationResponse, ResponseData } from "api/types/_public";
import {
  getNotifications,
  getTransmissionList
} from "./thunk";


export interface IState {
  notifications: ResponseData<INotification[]> & PaginationResponse | null,
  isNotificationLoading: boolean,
  isNotificationSuccess: boolean,

  allNotification: ResponseData<INotification[]> | null,
  listTransmission: any,

  dailyStatistics: ResponseData<INotification[]> & PaginationResponse | null,
  isDailyStatisticsLoading: boolean,
  isDailyStatisticsSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  notifications: null,
  isNotificationLoading: false,
  isNotificationSuccess: false,

  allNotification: null,
  listTransmission: null,

  dailyStatistics: null,
  isDailyStatisticsLoading: false,
  isDailyStatisticsSuccess: false,

  error: {},
};

const NotificationSlice = createSlice({
  name: "Notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Notifications
    builder.addCase(getNotifications.pending, (state: IState, action: any) => {
      state.isNotificationLoading = true
    });
    builder.addCase(getNotifications.fulfilled, (state: IState, action: any) => {
      state.notifications = action.payload.data;
      state.isNotificationSuccess = true;
      state.isNotificationLoading = false;
    });
    builder.addCase(getNotifications.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNotificationSuccess = false;
      state.isNotificationLoading = false;
    });

    //get Transmission List
    builder.addCase(getTransmissionList.pending, (state: IState, action: any) => {
      state.isNotificationLoading = true
    });
    builder.addCase(getTransmissionList.fulfilled, (state: IState, action: any) => {
      state.listTransmission = action.payload.data;
      state.isNotificationSuccess = true;
      state.isNotificationLoading = false;
    });
    builder.addCase(getTransmissionList.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNotificationSuccess = false;
      state.isNotificationLoading = false;
    });

  },
});

export default NotificationSlice.reducer;