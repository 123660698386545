import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IRole } from "api/types/_role";
import { getRoles } from "./thunk";

export interface IState {
  roles: (ResponseData<IRole[]> & PaginationResponse) | null;
  isRoleLoading: boolean;
  isRoleSuccess: boolean;

  allRole: ResponseData<IRole[]> | null;

  error: any;
}

export const initialState: IState = {
  roles: null,
  isRoleLoading: false,
  isRoleSuccess: false,

  allRole: null,

  error: {},
};

const RoleSlice = createSlice({
  name: "Role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Roles
    builder.addCase(getRoles.pending, (state: IState, action: any) => {
      state.isRoleLoading = true;
    });
    builder.addCase(getRoles.fulfilled, (state: IState, action: any) => {
      state.roles = action.payload.data;
      state.isRoleSuccess = true;
      state.isRoleLoading = false;
    });
    builder.addCase(getRoles.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRoleSuccess = false;
      state.isRoleLoading = false;
    });
  },
});

export default RoleSlice.reducer;
