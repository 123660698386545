import { Option } from "api/types/_public";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import ModalConfirm from "components/Common/ModalConfirm";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col } from "reactstrap";
import TableContainer from "../../../../components/Common/TableContainer";
import { deleteWinner, getWinners } from "../../../../store/thunks";

const typeQuery = {};

const TYPE_SELECT_DEFAULT: string = "created_at";

registerLocale("en", en);
registerLocale("ko", ko);

const WinnerList = ({
  id,
  type,
  isReLoad,
  updateSummary,
  triggerData,
}: any) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const [query, setQuery]: any = useState({
    time_request: 0,
    campaign_id: id,
    page: 1,
    limit: 30,
    type: type,
  });

  const [isLoadingWinners, setIsLoadingWinner] = useState(false);
  const [listWinners, setListWinners] = useState<any>({});

  const getListWinners = async () => {
    setIsLoadingWinner(true);

    try {
      const res: any = await getWinners(query);
      if (res?.code === 200) {
        setListWinners(res?.data);
        if (type === "1") {
          updateSummary({
            type: type,
            data: {
              totalWinner: res?.data?.pagination?.total,
            },
          });
        }
        if (type === "2") {
          updateSummary({
            type: type,
            data: {
              totalWinnerSelected: res?.data?.pagination?.total,
            },
          });
        }
      }
      setIsLoadingWinner(false);
    } catch (error) {
      setIsLoadingWinner(false);
    }
  };

  // useEffect(() => {
  //   getListWinners();
  // }, [triggerCount]);

  // Begin:: handle select table
  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const handleCheck = (
    valueCheck: Option,
    valueIndex: number | string,
    e: any = null
  ) => {
    const index = String(valueIndex);
    if (valueCheck?.value === "ALL") {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (listWinners?.list || [])?.map((item: any) => ({
            value: item?.subscription?.id,
            label: item?.id,
          }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter(
          (item: any) => item?.value !== valueCheck?.value
        );
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = [...prevClone[index], valueCheck];
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!optionsSelected[index]?.find(
      (x: any) => x.value === valueCheck?.value
    )?.value;
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (
      optionsSelected[valueIndexString]?.length > 0 &&
      optionsSelected[valueIndexString]?.length === listWinners?.list?.length
    );
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce(
      (total: number, [key, value]: any) => {
        return Number(total + (value || [])?.length);
      },
      0
    );
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce(
      (arr: Option[], [key, value]: any) => {
        return [...arr, ...(value || [])];
      },
      []
    );
  }, [optionsSelected]);

  // End:: handle select table
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const handleConfirmDelete = () => {
    setIsOpenConfirm((_prev) => true);
  };

  const onCloseConfirmClick = () => {
    setIsOpenConfirm((_prev) => false);
  };

  const handleActionDelete = async () => {
    if (!id && optionsSelected?.length === 0) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const ids = (optionsSelected || [])
        .flat()
        ?.map((item: any) => item?.value);
      const response: any = await deleteWinner({
        campaign_id: id,
        subscription_ids: ids,
        type: Number(type),
      });
      if (response?.code === 200) {
        getListWinners();
        setIsConfirmLoading((_prev) => false);
        setOptionsSelected((prev: any) => []);
        onCloseConfirmClick();
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Key"),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: "text-start",
        thWidth: 46,
        thComponent: () => (
          <>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isCheckedAll(query?.page)}
                onChange={(e) =>
                  handleCheck({ value: "ALL", label: "" }, query?.page, e)
                }
                value=""
                id={`cell-check-all`}
              />
              <label
                className="form-check-label"
                htmlFor={`cell-check-all`}
              ></label>
            </div>
          </>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="table"
                  checked={isChecked({
                    value: item?.subscription?.id,
                    label: item?.id,
                  })}
                  value={item?.subscription?.id ?? ""}
                  onChange={(e) =>
                    handleCheck(
                      { value: item?.subscription?.id, label: item?.id },
                      query?.page,
                      e
                    )
                  }
                  id={`cell-check-${item?.subscription?.id ?? ""}`}
                />
                <label
                  className="form-check-label"
                  htmlFor={`cell-check-${item?.subscription?.id ?? ""}`}
                ></label>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Phone number"),
        accessor: "applicant",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div>{item?.applicant?.phone_number}</div>
            </>
          );
        },
      },
      {
        Header: t("Number of support instances"),
        accessor: "number_of_support_instances",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(cell?.value || 0)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Number of selections"),
        accessor: "number_of_selections",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(cell?.value || 0)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thClass: "justify-content-center",
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center">{cell?.value}</div>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language, JSON.stringify(query), userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery((prev: any) => ({ ...prev, page: page + 1 }));
    // eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    getListWinners();
  }, [triggerData]);

  return (
    <React.Fragment>
      <div>
        <Col lg={12} className="text-end mb-3 mt-3">
          <div>
            <button
              type="button"
              className="btn btn-danger"
              disabled={countRowSelected <= 0}
              onClick={() => handleConfirmDelete()}
            >
              {countRowSelected > 0 && (
                <span
                  className="position-absolute topbar-badge badge rounded-pill bg-danger"
                  style={{ transform: "translate(0%, -70%)" }}
                >
                  {formatNumberWithCommas(countRowSelected)}
                  <span className="visually-hidden">
                    total keywords selected
                  </span>
                </span>
              )}
              {t("Button Delete")}
            </button>
          </div>
        </Col>
        <Col lg={12} className="px-0">
          <Card id="leadsList">
            <CardBody className="pt-3">
              <TableContainer
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="align-middle"
                theadClass="table-light"
                columns={columns}
                data={listWinners?.list?.length ? listWinners?.list : []}
                customPageSize={query.limit}
                customPageIndex={query.page - 1}
                totalRecords={listWinners?.pagination?.total}
                customPageCount={Math.ceil(
                  Number(listWinners?.pagination?.total) /
                    Number(listWinners?.pagination?.limit)
                )}
                handleChangePage={handleChangePage}
                manualSorting={true}
                sorting={{
                  sort_by: query.sort_by,
                  order_by: query.order_by,
                }}
                handleChangeSorting={handleChangeSorting}
                isLoading={isLoadingWinners}
              />
            </CardBody>
          </Card>
        </Col>
      </div>
      <ModalConfirm
        header={t("Button Delete Winner")}
        isOpen={isOpenConfirm}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmClick}
        onConfirm={handleActionDelete}
      />
    </React.Fragment>
  );
};

export default WinnerList;
