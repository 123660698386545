import classnames from "classnames";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { getUser, postUser, putUser } from "store/thunks";
import * as Yup from "yup";

//import images
import { IRole } from "api/types/_role";
import { IUser } from "api/types/_user";
import progileBg from "../../../assets/images/profile-bg.jpg";
import avatar1 from "../../../assets/images/users/user-dummy-img.jpg";

export interface Tag {
  id: string;
  text: string;
}

export interface Props {
  isModal?: boolean;
  id?: string;
  isCopy?: boolean;
  triggerRefresh?: () => void;
  listRole?: IRole[];
}

registerLocale("en", en);
registerLocale("ko", ko);

const UserForm = ({
  isModal = false,
  id = "",
  isCopy = false,
  triggerRefresh,
  listRole = [],
}: Props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

  const [initialValuesDefault, setInitialValuesDefault] =
    useState<IUser | null>(null);

  const [activeTab, setActiveTab] = useState("1");
  const [textExperience, setTextExperience] = useState(
    "You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. "
  );

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const LIST_OPTION_ROLE = listRole?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        role_id: values?.role?.value ?? LIST_OPTION_ROLE[0]?.value,
        first_name: values?.first_name ?? "",
        last_name: values?.last_name ?? "",
        email: values?.email ?? "",
        // description: values?.description ?? "",
        is_active: values?.is_active ?? 1,
        ...(id ? {} : { password: values?.password ?? "" }),
      };
      const response: any = id ? await putUser(id, data) : await postUser(data);
      if (response?.data) {
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh && triggerRefresh();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(`${t("First Name is required")}`),
    last_name: Yup.string().required(`${t("Last Name is required")}`),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required(`${t("Email is required")}`),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required(`${t("Password is required")}`),
    is_active: Yup.number(),
    description: Yup.string().nullable(),
    joining_date: Yup.string().nullable(),
    role: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${t("Role is required")}`),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      description: "",
      joining_date: "",
      is_active: 1,
      role: undefined,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleSetValueForm = (valueDefault: any) => {
    const vFirstName = valueDefault?.first_name || "";
    const vLastName = valueDefault?.last_name || "";
    const vEmail = valueDefault?.email || "";
    const vPassword = valueDefault?.password || "";
    const vIsActive = valueDefault?.is_active;
    const vDescription = valueDefault?.description || "";
    const vJoiningDate = valueDefault?.created_at || "";
    const vRole =
      LIST_OPTION_ROLE?.filter(
        (item: any) => String(valueDefault?.role_id) === String(item?.value)
      )[0] || LIST_OPTION_ROLE[0];

    setTimeout(() => {
      formik.setFieldValue("first_name", vFirstName);
      formik.setFieldValue("last_name", vLastName);
      formik.setFieldValue("email", vEmail);
      formik.setFieldValue("password", vPassword);
      formik.setFieldValue("description", vDescription);
      formik.setFieldValue("is_active", vIsActive);
      formik.setFieldValue("joining_date", vJoiningDate);
      formik.setFieldValue("role", vRole);
    }, 300);
  };

  const handleSubmitChangePassword = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        old_password: values?.old_password ?? "",
        new_password: values?.new_password ?? "",
      };
      const response: any = await putUser(id, data);
      if (response?.data) {
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh && triggerRefresh();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchemaChangePassword = Yup.object({
    old_password: Yup.string().required(`${t("Old Password is required")}`),
    new_password: Yup.string().required(`${t("News Password is required")}`),
    confirm_password: Yup.string().required(
      `${t("Confirm Password is required")}`
    ),
  });

  const formikChangePassword = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: validationSchemaChangePassword,
    onSubmit: handleSubmitChangePassword,
  });

  const handleCallAllOption = async (idItem: string) => {
    try {
      if (!idItem) {
        return;
      }
      setIsLoadingDetail((_prev) => true);
      const [resDetail]: any = await Promise.all([
        idItem ? getUser(idItem) : {},
      ]);
      if (resDetail?.data) {
        setInitialValuesDefault((_prev) => resDetail?.data);
        handleSetValueForm(resDetail?.data);
        setIsLoadingDetail((_prev) => false);
      }
    } catch (error: any) {
      setIsLoadingDetail((_prev) => false);
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    document.title = `${t("User Create")} - ${t("User")} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      {isLoadingDetail && (
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgb(164 164 164 / 36%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="sm" color="primary"></Spinner>
        </div>
      )}
      <div>
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                    <Input
                      id="profile-foreground-img-file-input"
                      type="file"
                      className="profile-foreground-img-file-input"
                    />
                    <Label
                      htmlFor="profile-foreground-img-file-input"
                      className="profile-photo-edit btn btn-light"
                    >
                      <i className="ri-image-edit-line align-bottom me-1"></i>{" "}
                      {t("Change Cover")}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={avatar1}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">
                      {formik?.values?.first_name || ""}{" "}
                      {formik?.values?.last_name || ""}
                    </h5>
                    <p className="text-muted mb-0">
                      {initialValuesDefault?.role?.name || ""}
                    </p>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-5">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">
                        {t("Complete Your Profile")}
                      </h5>
                    </div>
                    {/* <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-secondary fs-12"><i
                                                className="ri-edit-box-line align-bottom me-1"></i> Edit</Link>
                                        </div> */}
                  </div>
                  <div className="progress animated-progress custom-progress progress-label">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      style={{
                        width: `${Math.round(
                          100 -
                            20 *
                              Number(Object.keys(formik?.errors)?.length || 0)
                        )}%`,
                      }}
                    >
                      <div className="label">
                        {Math.round(
                          100 -
                            20 *
                              Number(Object.keys(formik?.errors)?.length || 0)
                        )}
                        %
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              {/* <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">{t('Portfolio')}</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-secondary fs-12"><i
                                                className="ri-add-fill align-bottom me-1"></i> {t('Button Add')}</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card> */}
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        {t("Personal Details")}
                      </NavLink>
                    </NavItem>
                    {/* {id && (<NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}
                                                type="button">
                                                {t('Change Password')}
                                            </NavLink>
                                        </NavItem>)} */}
                    {/* <NavItem >
                                                <NavLink to="#"
                                                    className={classnames({ active: activeTab === "3" })}
                                                    onClick={() => {
                                                        tabChange("3");
                                                    }}
                                                    type="button">
                                                    <i className="far fa-envelope"></i>
                                                    Experience
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink to="#"
                                                    className={classnames({ active: activeTab === "4" })}
                                                    onClick={() => {
                                                        tabChange("4");
                                                    }}
                                                    type="button">
                                                    <i className="far fa-envelope"></i>
                                                    Privacy Policy
                                                </NavLink>
                                            </NavItem> */}
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <form onSubmit={formik.handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                {t("First Name")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="first_name"
                                type="text"
                                className="form-control"
                                id="firstnameInput"
                                placeholder={t("Enter your firstname")}
                                value={formik?.values?.first_name}
                                onChange={(event: any) =>
                                  formik.setFieldValue(
                                    "first_name",
                                    event?.target?.value
                                  )
                                }
                              />
                              {formik.touched.first_name &&
                              formik.errors.first_name ? (
                                <div className="text-danger mt-2">
                                  {formik.errors.first_name}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="lastnameInput"
                                className="form-label"
                              >
                                {t("Last Name")}{" "}
                                <span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="last_name"
                                type="text"
                                className="form-control"
                                id="lastnameInput"
                                placeholder={t("Enter your lastname")}
                                value={formik?.values?.last_name}
                                onChange={(event: any) =>
                                  formik.setFieldValue(
                                    "last_name",
                                    event?.target?.value
                                  )
                                }
                              />
                              {formik.touched.last_name &&
                              formik.errors.last_name ? (
                                <div className="text-danger mt-2">
                                  {formik.errors.last_name}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label"
                              >
                                {t("Email Address")}{" "}
                                <span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="email"
                                type="text"
                                className="form-control"
                                id="emailInput"
                                placeholder={t("Enter your email")}
                                value={formik?.values?.email}
                                onChange={(event: any) =>
                                  formik.setFieldValue(
                                    "email",
                                    event?.target?.value
                                  )
                                }
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger mt-2">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          {!id && (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="passwordInput"
                                  className="form-label"
                                >
                                  {t("Password")}{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Input
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  id="passwordInput"
                                  placeholder={t("Enter Password")}
                                  value={formik?.values?.password}
                                  onChange={(event: any) =>
                                    formik.setFieldValue(
                                      "password",
                                      event?.target?.value
                                    )
                                  }
                                />
                                {formik.touched.password &&
                                formik.errors.password ? (
                                  <div className="text-danger mt-2">
                                    {formik.errors.password}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          )}
                          {id && (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="JoiningdatInput"
                                  className="form-label"
                                >
                                  {t("Joining Date")}
                                </Label>
                                <Input
                                  name="joining_date"
                                  className="form-control"
                                  disabled
                                  value={formik?.values?.joining_date || ""}
                                />
                              </div>
                            </Col>
                          )}
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="lastnameInput"
                                className="form-label"
                              >
                                {t("Role")}{" "}
                                <span className="text-danger"> *</span>
                              </Label>
                              <Select
                                name="role"
                                className="mb-0"
                                placeholder={t("Role")}
                                value={
                                  formik?.values?.role || LIST_OPTION_ROLE[0]
                                }
                                onChange={(event: any) =>
                                  formik.setFieldValue("role", event)
                                }
                                options={LIST_OPTION_ROLE}
                              ></Select>
                              {formik.touched.role && formik.errors.role ? (
                                <div className="text-danger mt-2">
                                  {formik.errors.role}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          {/* <Col lg={12}>
                                                        <div className="mb-3 pb-2">
                                                            <Label htmlFor="exampleFormControlTextarea"
                                                                className="form-label">{t('Description')}</Label>
                                                            <textarea
                                                                name="description"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea"
                                                                placeholder={t('Enter Description')}
                                                                rows={3}
                                                                value={formik?.values?.description}
                                                                onChange={(event: any) => formik.setFieldValue('description', event?.target?.value)}
                                                            />
                                                            {formik.touched.description && formik.errors.description ? (
                                                                <div className="text-danger mt-2">{formik.errors.description}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col> */}
                          <Col lg={12}>
                            <div className="mb-3">
                              <h5 className="card-title text-decoration-underline mb-3">
                                {t("Application Account")}:
                              </h5>
                              <ul className="list-unstyled mb-0">
                                <li className="d-flex">
                                  <div className="flex-grow-1">
                                    <label
                                      htmlFor="directMessage"
                                      className="form-check-label fs-14"
                                    >
                                      {t("Setting Active")}
                                    </label>
                                    <p className="text-muted">
                                      {t(
                                        "Allows the account to log into the system"
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="form-check form-switch">
                                      <Input
                                        name="is_active"
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="directMessage"
                                        checked={
                                          !!Number(formik?.values?.is_active)
                                        }
                                        onChange={(event: any) =>
                                          formik.setFieldValue(
                                            "is_active",
                                            Number(event?.target.checked)
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary fs-14"
                              >
                                {isLoading ? (
                                  <Spinner size="sm me-2"></Spinner>
                                ) : !!id ? (
                                  <i className="ri-login-circle-line align-bottom me-2 "></i>
                                ) : (
                                  <i className="ri-add-fill align-bottom me-2"></i>
                                )}
                                {id
                                  ? t("Button Update User")
                                  : t("Button Create User")}
                              </button>
                              {/* <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseClick} disabled={isLoading}>
                                                                <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                                                {t('Button Close')}
                                                            </button> */}
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </TabPane>

                    <TabPane tabId="2">
                      <form onSubmit={formikChangePassword.handleSubmit}>
                        <Row className="g-2">
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="oldpasswordInput"
                                className="form-label"
                              >
                                {t("Old Password")}{" "}
                                <span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="old_password"
                                type="password"
                                className="form-control"
                                id="oldpasswordInput"
                                placeholder={t("Old Password")}
                                value={
                                  formikChangePassword?.values?.old_password
                                }
                                onChange={(event: any) =>
                                  formikChangePassword.setFieldValue(
                                    "old_password",
                                    event?.target?.value
                                  )
                                }
                              />
                              {formikChangePassword.touched.old_password &&
                              formikChangePassword.errors.old_password ? (
                                <div className="text-danger mt-2">
                                  {formikChangePassword.errors.old_password}
                                </div>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="newpasswordInput"
                                className="form-label"
                              >
                                {t("New Password")}{" "}
                                <span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="new_password"
                                type="password"
                                className="form-control"
                                id="newpasswordInput"
                                placeholder={t("New Password")}
                                value={
                                  formikChangePassword?.values?.new_password
                                }
                                onChange={(event: any) =>
                                  formikChangePassword.setFieldValue(
                                    "new_password",
                                    event?.target?.value
                                  )
                                }
                              />
                              {formikChangePassword.touched.new_password &&
                              formikChangePassword.errors.new_password ? (
                                <div className="text-danger mt-2">
                                  {formikChangePassword.errors.new_password}
                                </div>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="confirmpasswordInput"
                                className="form-label"
                              >
                                {t("Confirm Password")}{" "}
                                <span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="confirm_password"
                                type="password"
                                className="form-control"
                                id="confirmpasswordInput"
                                placeholder={t("Confirm Password")}
                                value={
                                  formikChangePassword?.values?.confirm_password
                                }
                                onChange={(event: any) =>
                                  formikChangePassword.setFieldValue(
                                    "confirm_password",
                                    event?.target?.value
                                  )
                                }
                              />
                              {formikChangePassword.touched.confirm_password &&
                              formikChangePassword.errors.confirm_password ? (
                                <div className="text-danger mt-2">
                                  {formikChangePassword.errors.confirm_password}
                                </div>
                              ) : null}
                            </div>
                          </Col>

                          {/* <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Link to="#"
                                                                className="link-primary text-decoration-underline">Forgot
                                                                Password ?</Link>
                                                        </div>
                                                    </Col> */}

                          <Col lg={12}>
                            <div className="text-end">
                              <button type="submit" className="btn btn-primary">
                                {isLoading && (
                                  <Spinner size="sm me-2"></Spinner>
                                )}
                                {t("Change Password")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                      <div className="mt-4 mb-3 border-bottom pb-2">
                        <div className="float-end">
                          <Link to="#" className="link-secondary">
                            {t("All Logout")}
                          </Link>
                        </div>
                        <h5 className="card-title">{t("Login History")}</h5>
                      </div>
                      {/* <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-smartphone-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>iPhone 12 Pro</h6>
                                                    <p className="text-muted mb-0">Los Angeles, United States - March 16 at
                                                        2:47PM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div> */}
                    </TabPane>

                    <TabPane tabId="3">
                      <form>
                        <div id="newlink">
                          <div id="1">
                            <Row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="jobTitle"
                                    className="form-label"
                                  >
                                    Job Title
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="jobTitle"
                                    placeholder="Job title"
                                    defaultValue="Lead Designer / Developer"
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="companyName"
                                    className="form-label"
                                  >
                                    Company Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    placeholder="Company name"
                                    defaultValue="Themesbrand"
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="experienceYear"
                                    className="form-label"
                                  >
                                    Experience Years
                                  </label>
                                  <Row>
                                    <Col lg={5}>
                                      <select
                                        className="form-control"
                                        data-choices
                                        data-choices-search-false
                                        name="experienceYear"
                                        id="experienceYear"
                                      >
                                        <option defaultValue="">
                                          Select years
                                        </option>
                                        <option value="Choice 1">2001</option>
                                        <option value="Choice 2">2002</option>
                                        <option value="Choice 3">2003</option>
                                        <option value="Choice 4">2004</option>
                                        <option value="Choice 5">2005</option>
                                        <option value="Choice 6">2006</option>
                                        <option value="Choice 7">2007</option>
                                        <option value="Choice 8">2008</option>
                                        <option value="Choice 9">2009</option>
                                        <option value="Choice 10">2010</option>
                                        <option value="Choice 11">2011</option>
                                        <option value="Choice 12">2012</option>
                                        <option value="Choice 13">2013</option>
                                        <option value="Choice 14">2014</option>
                                        <option value="Choice 15">2015</option>
                                        <option value="Choice 16">2016</option>
                                        <option value="Choice 17">2017</option>
                                        <option value="Choice 18">2018</option>
                                        <option value="Choice 19">2019</option>
                                        <option value="Choice 20">2020</option>
                                        <option value="Choice 21">2021</option>
                                        <option value="Choice 22">2022</option>
                                      </select>
                                    </Col>

                                    <div className="col-auto align-self-center">
                                      to
                                    </div>

                                    <Col lg={5}>
                                      <select
                                        className="form-control"
                                        data-choices
                                        data-choices-search-false
                                        name="choices-single-default2"
                                      >
                                        <option defaultValue="">
                                          Select years
                                        </option>
                                        <option value="Choice 1">2001</option>
                                        <option value="Choice 2">2002</option>
                                        <option value="Choice 3">2003</option>
                                        <option value="Choice 4">2004</option>
                                        <option value="Choice 5">2005</option>
                                        <option value="Choice 6">2006</option>
                                        <option value="Choice 7">2007</option>
                                        <option value="Choice 8">2008</option>
                                        <option value="Choice 9">2009</option>
                                        <option value="Choice 10">2010</option>
                                        <option value="Choice 11">2011</option>
                                        <option value="Choice 12">2012</option>
                                        <option value="Choice 13">2013</option>
                                        <option value="Choice 14">2014</option>
                                        <option value="Choice 15">2015</option>
                                        <option value="Choice 16">2016</option>
                                        <option value="Choice 17">2017</option>
                                        <option value="Choice 18">2018</option>
                                        <option value="Choice 19">2019</option>
                                        <option value="Choice 20">2020</option>
                                        <option value="Choice 21">2021</option>
                                        <option value="Choice 22">2022</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="jobDescription"
                                    className="form-label"
                                  >
                                    Job Description
                                  </Label>
                                  <textarea
                                    className="form-control"
                                    id="jobDescription"
                                    rows={3}
                                    placeholder="Enter description"
                                    value={textExperience}
                                    onChange={(e: any) =>
                                      setTextExperience(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <div className="hstack gap-2 justify-content-end">
                                <Link className="btn btn-success" to="#">
                                  Delete
                                </Link>
                              </div>
                            </Row>
                          </div>
                        </div>
                        <div id="newForm" style={{ display: "none" }}></div>
                        <Col lg={12}>
                          <div className="hstack gap-2">
                            <button
                              type="submit"
                              className="btn btn-success"
                              onClick={(e) => e.preventDefault()}
                            >
                              Update
                            </button>
                            <Link to="#" className="btn btn-primary">
                              Add New
                            </Link>
                          </div>
                        </Col>
                      </form>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="mb-4 pb-2">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Security:
                        </h5>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                          <div className="flex-grow-1">
                            <h6 className="fs-14 mb-1">
                              Two-factor Authentication
                            </h6>
                            <p className="text-muted">
                              Two-factor authentication is an enhanced security
                              meansur. Once enabled, you'll be required to give
                              two types of identification when you log into
                              Google Authentication and SMS are Supported.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Enable Two-facor Authentication
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-14 mb-1">
                              Secondary Verification
                            </h6>
                            <p className="text-muted">
                              The first factor is a password and the second
                              commonly includes a text with a code sent to your
                              smartphone, or biometrics using your fingerprint,
                              face, or retina.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Set up secondary method
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-14 mb-1">Backup Codes</h6>
                            <p className="text-muted mb-sm-0">
                              A backup code is automatically generated for you
                              when you turn on two-factor authentication through
                              your iOS or Android Twitter app. You can also
                              generate a backup code on twitter.com.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Generate backup codes
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Application Notifications:
                        </h5>
                        <ul className="list-unstyled mb-0">
                          <li className="d-flex">
                            <div className="flex-grow-1">
                              <label
                                htmlFor="directMessage"
                                className="form-check-label fs-14"
                              >
                                Direct messages
                              </label>
                              <p className="text-muted">
                                Messages from people you follow
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="directMessage"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="desktopNotification"
                              >
                                Show desktop notifications
                              </Label>
                              <p className="text-muted">
                                Choose the option you want as your default
                                setting. Block a site: Next to "Not allowed to
                                send notifications," click Add.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="desktopNotification"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="emailNotification"
                              >
                                Show email notifications
                              </Label>
                              <p className="text-muted">
                                {" "}
                                Under Settings, choose Notifications. Under
                                Select an account, choose the account to enable
                                notifications for.{" "}
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="emailNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="chatNotification"
                              >
                                Show chat notifications
                              </Label>
                              <p className="text-muted">
                                To prevent duplicate mobile notifications from
                                the Gmail and Chat apps, in settings, turn off
                                Chat notifications.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="chatNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="purchaesNotification"
                              >
                                Show purchase notifications
                              </Label>
                              <p className="text-muted">
                                Get real-time purchase alerts to protect
                                yourself from fraudulent charges.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="purchaesNotification"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h5 className="card-title text-decoration-underline mb-3">
                          Delete This Account:
                        </h5>
                        <p className="text-muted">
                          Go to the Data & Privacy section of your profile
                          Account. Scroll to "Your data & privacy options."
                          Delete your Profile Account. Follow the instructions
                          to delete your account :
                        </p>
                        <div>
                          <Input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Enter your password"
                            defaultValue="make@321654987"
                            style={{ maxWidth: "265px" }}
                          />
                        </div>
                        <div className="hstack gap-2 mt-3">
                          <Link to="#" className="btn btn-soft-primary">
                            Close & Delete This Account
                          </Link>
                          <Link to="#" className="btn btn-light">
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default UserForm;
