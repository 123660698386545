import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import Profile from "pages/User/Profile";
import RoleList from "pages/User/Role";
import UserList from "pages/User/User";

// Campaign
import CampaignCreate from "pages/Campaign/CampaignForm";
import CampaignList from "pages/Campaign/CampaignList";

// Media
import MediaCreate from "pages/Media/Media/MediaForm";
import MediaList from "pages/Media/Media/MediaList";

// Device
import DeviceList from "pages/Media/Device";

import NotificationLogList from "pages/Statistics/NotificationLog";
import ClickLogList from "pages/Statistics/ClickLog";

import MediaStatistics from "pages/Statistics/MediaStatistics";

import SSORedirect from "pages/SSORedirect";
// import UnSubscriptionsLog from "pages/Statistics/UnSubscriptionLog";
import DailyStatistics from "pages/Statistics/DailyStatitstics";
import TagStatistics from "pages/Statistics/TagStatistics";
import UserStatistics from "pages/Statistics/UserStatistics";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardCrm /> },

  { path: "/user/list", component: <UserList /> },

  { path: "/user/role", component: <RoleList /> },

  { path: "/user/profile", component: <Profile /> },

  { path: "campaign/list", component: <CampaignList /> },

  { path: "campaign/create", component: <CampaignCreate /> },

  { path: "/media/list", component: <MediaList /> },
  { path: "/media/create", component: <MediaCreate /> },

  { path: "/media/subscription", component: <DeviceList /> },

  { path: "/statistics/notification-log", component: <NotificationLogList /> },

  { path: "/statistics/click-log", component: <ClickLogList /> },

  { path: "/statistics/daily-statistics", component: <DailyStatistics /> },
  { path: "/statistics/media-statistics", component: <MediaStatistics /> },
  { path: "/statistics/tag-statistics", component: <TagStatistics /> },
  { path: "/statistics/user-statistics", component: <UserStatistics /> },
  // { path: "/statistics/un-subscription-log", component: <UnSubscriptionsLog /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/sso-redirect", component: <SSORedirect /> },
];

export { authProtectedRoutes, publicRoutes };
