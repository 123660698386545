import { IPermission, IRole } from "api/types/_role";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import {
  getAllPermissions,
  getRoles as onGetRoles,
} from "../../../store/thunks";
import RoleForm from "./RoleForm";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "id";

registerLocale("en", en);
registerLocale("ko", ko);

const RoleList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    name: withDefault(StringParam, ""),
  });
  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );
  //eslint-disable-next-line
  const [mediasSearch, setMediasSearch] = useState<Option[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [roleEdit, setRoleEdit] = useState<
    (IRole & { isCopy?: boolean }) | null
  >(null);
  const [permissions, setPermissions] = useState<IPermission[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Role;

  const RoleProperties = createSelector(selectLayoutState, (state) => ({
    roles: state.roles,
    isRoleSuccess: state.isRoleSuccess,
    isRoleLoading: state.isRoleLoading,
    error: state.error,
  }));

  // Inside your component
  const { roles, isRoleLoading } = useSelector(RoleProperties);

  useEffect(() => {
    dispatch(onGetRoles(query));
    //eslint-disable-next-line
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        name: undefined,
        sort_by: TYPE_SELECT_DEFAULT,
        time_request: +new Date(),
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setMediasSearch([]);
  };

  // Begin::Update Role
  const onCloseClick = () => {
    setRoleEdit((_prev) => null);
    setIsOpen((_prev) => false);
  };

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetRoles(query));
  };
  //End::Update Role

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Id"),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t("Name"),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t("Description"),
        accessor: "description",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      // {
      //   Header: t('Active'),
      //   accessor: "is_active",
      //   filterable: false,
      //   sortable: false,
      //   Cell: (cell: any) => {
      //     const value: any = cell?.row?.original;
      //     const active = value?.is_active;
      //     return (
      //       <div className="form-check form-switch form-switch-sm" dir="ltr">
      //         <input type="checkbox" className="form-check-input" name="is_active" id="customSwitchsizelg" checked={Boolean(active)}
      //         // onChange={(event: any) => handleChangeStatusItem(value, event.target.checked)}
      //         />
      //       </div>
      //     );
      //   }
      // },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              {isHavePermissionRole(
                ROLES_FOR_APP.ROLE_UPDATE,
                userPermissions
              ) && (
                <TooltipCustom
                  title={t("Button Update Role")}
                  id={`update-rol-${item?.id}`}
                >
                  <li
                    className=" list-inline-item me-0"
                    id={`update-rol-${item?.id}`}
                  >
                    <Link
                      className="btn btn-sm btn-soft-secondary edit-item-btn"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setRoleEdit((prev) => item);
                      }}
                    >
                      <i className="ri-pencil-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    //eslint-disable-next-line
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    //eslint-disable-next-line
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resPermissions]: any = await Promise.all([getAllPermissions()]);
      if (resPermissions?.data) {
        setPermissions((_prev) => resPermissions?.data);
      }
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t("Role")} - ${t("Role")} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Role")} pageTitle={t("Role")} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.ROLE_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} lg={3} className="mt-2">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Name")}...`}
                          value={keywordSearch}
                          onChange={(e) => setKeywordSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>
                      <Col
                        sm={12}
                        lg={9}
                        className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isRoleLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                        {isHavePermissionRole(
                          ROLES_FOR_APP.ROLE_CREATE,
                          userPermissions
                        ) && (
                          <button
                            type="button"
                            className="btn btn-success me-1"
                            onClick={() => setIsOpen((_prev) => true)}
                          >
                            <i className="ri-add-fill align-bottom me-1"></i>{" "}
                            {t("Button Create Role")}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={roles?.list?.length ? roles?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={roles?.pagination?.total}
                        customPageCount={Math.ceil(
                          Number(roles?.pagination?.total) /
                            Number(roles?.pagination?.limit)
                        )}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{
                          sort_by: query.sort_by,
                          order_by: query.order_by,
                        }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isRoleLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={!!roleEdit?.id || isOpen}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>
              {!!roleEdit?.id
                ? t("Button Update Role")
                : t("Button Create Role")}
            </>{" "}
            <>{roleEdit?.id && (" - " + (roleEdit?.name || "") || "")}</>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <RoleForm
                isModal={true}
                permissions={permissions || []}
                id={String(roleEdit?.id || "")}
                isCopy={!!roleEdit?.isCopy}
                triggerRefresh={triggerRefresh}
                onCloseClick={onCloseClick}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default RoleList;
