//Include Both Helper File with needed methods
import tagStatisticsApi from "api/tagStatisticsApi";

export const getTagStatistics = async (params: any = {}) => {
  if (params && params.time_request) {
    delete params["time_request"];
  }

  try {
    const response = await tagStatisticsApi.tagStatistics(params);
    return response;
  } catch (error) {
    return error;
  }
};
