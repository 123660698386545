import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";

export interface IState {
  tagStatistics: (ResponseData<any[]> & PaginationResponse) | null;
  isTagStatisticsLoading: boolean;
  isTagStatisticsSuccess: boolean;

  allTagStatistics: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  tagStatistics: null,
  isTagStatisticsLoading: false,
  isTagStatisticsSuccess: false,

  allTagStatistics: null,

  error: {},
};

const TagStatisticSlice = createSlice({
  name: "TagStatistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Notifications
    // builder.addCase(getTagStatistics.pending, (state: IState, action: any) => {
    //   state.isTagStatisticsLoading = true;
    // });
    // builder.addCase(
    //   getTagStatistics.fulfilled,
    //   (state: IState, action: any) => {
    //     state.tagStatistics = action.payload.data;
    //     state.isTagStatisticsSuccess = true;
    //     state.isTagStatisticsLoading = false;
    //   }
    // );
    // builder.addCase(getTagStatistics.rejected, (state: IState, action: any) => {
    //   state.error = action.payload.error || null;
    //   state.isTagStatisticsSuccess = false;
    //   state.isTagStatisticsLoading = false;
    // });
  },
});

export default TagStatisticSlice.reducer;
