import React, { useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";

const TooltipCustom = ({ title = "", id = "", children, className = '', style = {} }: any) => {
  const ref = useRef(null);
  return (
    <React.Fragment>
      <li className={className} style={{ listStyle: "none", ...style }} ref={ref}>
        {children}
      </li>
      <UncontrolledTooltip target={ref}>{title}</UncontrolledTooltip>
    </React.Fragment>
  );
};

export default TooltipCustom;
